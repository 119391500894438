.sidebar-asking {
  & .online-check-doctor {
    padding-top: 0;

    & .check-doctor-form-wrapper {
      box-shadow: 0 3px 10px -1px rgba(0, 0, 0, .08);
      width: 100% !important;
      max-width: 300px;
      padding: 10px 31px 31px !important;
      @media screen and (max-width: 1024px) {
        width: fit-content !important;
      }

      & .form-row {
        margin-bottom: 0;

        &.small {
          & .picker {
            &.datetime {
              width: 140px;

              & input {
                width: 140px !important;
              }
            }

            &.time {
              width: 142px;

              & input {
                width: 110px !important;
                text-align: left;
              }
            }
          }
        }

        & .form-submit {
          margin-top: 30px;
        }
      }

      & .form-group {
        width: 100%;
        margin-right: 0;

        & input {
          width: 100% !important;
          padding: 10px 10px 10px 10px;
        }

        & .select {
          width: 100%;
        }

        &.select-group {
          height: auto;
        }
      }
    }
  }
}

.sidebar-asking {
  display: flex;
  justify-content: center;
}

.sidebar-asking .online-check-doctor .check-doctor-form .form-row .form-group .picker.time::after{
  right: 37px;
  top: 10px;
}
