.banner {
  & .container {
    max-width: 1600px;
  }

  &__home2 {
    //background: url(../img/home-2.jpg) center center no-repeat;
    //background-size: cover;
    //background-image: url("../img/home-2.jpg");
    background-repeat: no-repeat;
    background-position: initial;
    background-size: cover;
    //background-color: #ffdc5a;
    height: 685px;
    border-bottom: 18px solid #fff;
    @media screen and (max-width: 991px) {
      background-image: url(../img/home2mob.jpg) !important;
      background-size: cover;
      background-position: left;
    }
    @media screen and (max-width: 568px) {
      background-size: inherit;
      height: 515px;
      background-position: top;
    }
  }

  &__home3 {
    //background: url(../img/home-2.jpg) center center no-repeat;
    //background-size: cover;
    //background-image: url("../img/home-3.jpg");
    background-repeat: no-repeat;
    background-position: initial;
    background-size: cover;
    //background-color: #ffdc5a;
    height: 685px;
    border-bottom: 18px solid #fff;
    @media screen and (max-width: 991px) {
      background-image: url(../img/home3mob.jpg) !important;
      background-size: cover;
      background-position: right;
    }
    @media screen and (max-width: 568px) {
      background-size: inherit;
      height: 515px;
      background-position: top;
    }
  }

  &__home4 {
    //background: url(../img/home-2.jpg) center center no-repeat;
    //background-size: cover;
    //background-image: url("../img/home-3.jpg");
    background-repeat: no-repeat;
    background-position: initial;
    background-size: cover;
    //background-color: #ffdc5a;
    height: 685px;
    border-bottom: none;
    @media screen and (max-width: 991px) {
      background-image: url(../img/home4mob.jpg) !important;
      background-size: cover;
      background-position: left;

    }
    @media screen and (max-width: 568px) {
      background-size: cover;
      height: 515px;
    }
  }
}

.banner-slogan {
  font-family: $gothamproregular;
  font-size: 42px;
  color: $color-grey;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 991px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center !important;
    max-width: initial !important;
  }

  & p {
    font-family: $gothampromedium;
    @media screen and (max-width: 991px) {
      font-size: 22px !important;
    }

    &:nth-of-type(2) {
      font-family: $gothamproregular;
      font-size: 36px;
      margin-bottom: 5px;
      @media screen and (max-width: 991px) {
        font-family: $gothamproregular;
        font-size: 16px !important;
        margin-bottom: 5px;
        line-height: 22px;
      }
    }
  }

  &_slogan1 {
    position: absolute;
    text-align: left;
    left: 111px;
    z-index: 1;
    top: -61px;
    @media screen and (max-width: 1366px) {
      left: 8%;
      font-size: 37px;
    }
    @media screen and (max-width: 1280px) {
      left: 6%;
      font-size: 33px;
    }
    @media screen and (max-width: 991px) {
      position: initial;
      text-align: center;
      font-size: 22px;
      margin-bottom: 13px;
      & p {
        &:nth-of-type(1) {
          margin-bottom: 8px;
        }
      }
    }
  }

  &_slogan2 {
    font-size: 28px;
    text-align: left;
    height: 100%;
    //max-width: 585px;
    justify-content: flex-start;
    padding-top: 26%;
    max-width: 710px;
    @media screen and (max-width: 991px) {
      padding-top: 27px;
    }

    & p {
      font-family: $gothampromedium;

      &:nth-of-type(1) {
        font-size: 45px;
        margin-bottom: 28px;
        @media screen and (max-width: 568px) {
          margin-bottom: 12px;
        }
      }

      &:nth-of-type(2) {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 56px;
        @media screen and (max-width: 991px) {
          margin-bottom: 33px;
          line-height: 22px;
        }
        @media screen and (max-width: 568px) {
          margin-bottom: 14px;
        }
      }

      &:nth-of-type(3) {
        text-align: left;
        max-width: 200px;
      }
    }
  }

  &_slogan3 {
    font-size: 28px;
    text-align: left;
    height: 100%;
    //max-width: 585px;
    justify-content: flex-start;
    padding-top: 15%;
    padding-left: 112px;
    @media screen and (max-width: 991px) {
      padding-top: 27px;
      padding-left: 0;
    }

    & p {
      font-family: $gothampromedium;

      &:nth-of-type(1) {
        font-size: 45px;
        margin-bottom: 28px;
        @media screen and (max-width: 568px) {
          margin-bottom: 12px;
        }
      }

      &:nth-of-type(2) {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 56px;
        @media screen and (max-width: 991px) {
          margin-bottom: 33px;
          line-height: 22px;
        }
        @media screen and (max-width: 568px) {
          margin-bottom: 14px;
        }
      }

      &:nth-of-type(3) {
        text-align: left;
        max-width: 200px;
        @media screen and (max-width: 991px) {
          & .btn {
            &.grey_inherit {
              border: 2px solid $color-grey2;
              background-color: $color-grey2;
              color: #fff;

              &:hover {
                border: 2px solid $color-grey2;
                background-color: inherit;
                color: $color-grey2;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  &_slogan4 {
    color: #fff;
    font-size: 28px;
    text-align: left;
    height: 100%;
    //max-width: 585px;
    justify-content: flex-start;
    padding-top: 19%;
    max-width: 710px;
    @media screen and (max-width: 991px) {
      padding-top: 27px;
    }

    & p {
      font-family: $gothampromedium;
      color: #fff;

      &:nth-of-type(1) {
        font-size: 45px;
        margin-bottom: 28px;
        @media screen and (max-width: 568px) {
          margin-bottom: 12px;
        }
      }

      &:nth-of-type(2) {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 56px;
        @media screen and (max-width: 991px) {
          margin-bottom: 33px;
          line-height: 22px;
        }
        @media screen and (max-width: 568px) {
          margin-bottom: 14px;
        }
      }

      &:nth-of-type(3) {
        text-align: left;
        max-width: 200px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .banner__home2{
    background-position-x: 10%;
  }
  .banner__home3 {
    background-position-x: 50%;
  }
  .banner__home4 {
    background-position-x: 40%;
  }
}
@media screen and (max-width: 1366px){
  .banner__home3 {
    background-position-x: 62%;
  }
}

@media screen and (max-width: 1024px) {
  .banner__home2{
    background-position-x: 35%;
  }
  .banner__home3 {
    background-position-x: 70%;
  }
  .banner__home4 {
    background-position-x: 40%;
  }
}


@media screen and (max-width: 1024px) {

  .banner__home4 {
    background-position-x: 40%;
  }
}

@media screen and (max-width: 375px) {
  .banner__home2 {
    background-position-x: inherit;
  }
  .banner__home3 {
    background-position-x: 53%;
  }
  .banner__home4 {
    background-position-x: inherit;
  }
}