.service-item-content {
  position: relative;
  padding-top: 25px;
  @media screen and (max-width: 991px){
    position: initial;
    padding-top: 30px;
  }
}

.service-heading-section {
  //position: absolute;
  //top: 0;
  //left: 0;
  //margin-bottom: 305px;
  width: 100%;
  @media screen and (max-width: 991px){
    position: initial;
    margin-bottom: 0;
  }
  & h1, h2, h3{
    font-family: $gothampromedium;
    font-size: 27px;
    line-height: 24px;
    color: $color-grey;
    text-align: left;
    width: 100%;
    @media screen and (max-width: 991px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
}

.side-wrapper{
  @media screen and (max-width: 991px){
    position: initial;
    margin-top: 70px;

  }
}

.owl-service-staff{
  margin-top: 100px;
}

.service-item-content{
 & .side-wrapper{
    position: absolute;
    top: -64px;
    @media screen and (max-width: 991px){
      position: initial;

    }
  }
}
.service-heading{
  & h1, h2, h3{
    font-family: $gothampromedium;
    font-size: 27px;
    line-height: 24px;
    color: $color-grey;
    text-align: left;
    width: 100%;
    @media screen and (max-width: 991px) {
      font-size: 22px;
      line-height: 28px;
    }
    @media screen and (max-width: 568px){
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

.intro-about{
  & h1, h2, h3{
    font-family: $gothampromedium;
    font-size: 27px;
    line-height: 24px;
    color: $color-grey;
    text-align: center;
    width: 100%;
    margin-bottom: 43px;
    @media screen and (max-width: 991px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
}