@font-face {
  font-family: "GothamProRegular";
  src: url("#{$font-path}GothamPro-Regular.eot");
  src: url("#{$font-path}GothamPro-Regular.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}GothamPro-Regular.woff") format("woff"),
  url("#{$font-path}GothamPro-Regular.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "GothamProMedium";
  src: url("#{$font-path}GothamPro-Medium.eot");
  src: url("#{$font-path}GothamPro-Medium.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}GothamPro-Medium.woff") format("woff"),
  url("#{$font-path}GothamPro-Medium.ttf") format("truetype");
  //font-weight: 200;
  font-style: normal;
}


@font-face {
  font-family: "RobotoLight";
  src: url("#{$font-path}RobotoLight.eot");
  src: url("#{$font-path}RobotoLight.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}RobotoLight.woff") format("woff"),
  url("#{$font-path}RobotoLight.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "RobotoMedium";
  src: url("#{$font-path}RobotoMedium.eot");
  src: url("#{$font-path}RobotoMedium.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}RobotoMedium.woff") format("woff"),
  url("#{$font-path}RobotoMedium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "RobotoRegular";
  src: url("#{$font-path}RobotoRegular.eot");
  src: url("#{$font-path}RobotoRegular.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}RobotoRegular.woff") format("woff"),
  url("#{$font-path}RobotoRegular.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "RobotoBold";
  src: url("#{$font-path}RobotoBold.eot");
  src: url("#{$font-path}RobotoBold.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}RobotoBold.woff") format("woff"),
  url("#{$font-path}RobotoBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}