header {
  &.second {
    background-color: #fff;
    //height: 100vh;
    //max-height: 702px;
    @media screen and (max-width: 991px) {
      height: 100%;
      box-shadow: 0 10px 10px -9px rgba(0, 0, 0, .5);
    }

    & .header-wrapper {
      //position: relative;
      //height: auto;
      padding-top: 27px;
      padding-bottom: 26px;
      box-shadow: 0px 10px 10px -9px rgba(0, 0, 0, 0.5);
      position: fixed;
      width: 100%;
      height: 155px;
      z-index: 2;
      background-color: #fff;
      //margin-bottom: 14px;
      //& .header-bg {
      //  max-width: 100%;
      //  position: absolute;
      //  margin: 0 auto;
      //  width: 100%;
      //}
      @media screen and (max-width: 991px) {
        background-image: none;
        display: none;
      }

      & .main-header-elem {
        justify-content: flex-end;

        & .search-form {
          padding-bottom: 8px;
          width: 160px;
          margin-right: 28px;
          @media screen and (max-width: 1024px) {
            width: auto;
            margin-right: 0;
          }

          & input {
            width: 131px;
            @media screen and (max-width: 1024px) {
              width: auto;
              &::placeholder {
                font-size: 12px;
              }
            }
          }

          & button {
            & svg {
              width: 15px;
              height: 15px;
            }
          }
        }

        & .btn {
          padding: 11px 7px;
          font-size: 14px;
          @media screen and (max-width: 1280px) {
            font-size: 13px;
            padding: 7px 9px;
          }
        }
      }
    }
    & .slider {
      padding-top: 155px;
      @media screen and (max-width: 991px){
        padding-top: 0;
      }
    }
    & .logo {
      @media screen and (max-width: 991px) {
        width: 100%;
        text-align: left;
        padding-left: 23px;
        margin-bottom: 50px;
      }

      & .logo-img {
        max-width: 203px;
        @media screen and (max-width: 1280px) {
          max-width: 190px;
        }
      }
    }
  }
}

.top-contact {

}

.top-menu-wrapper {
  padding-left: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}


.top-menu {
  display: flex;
  justify-content: space-around;
  width: 100%;

  & li {
    & a {
      color: $color-grey;
      font-size: 16px;
      font-family: $gothampromedium;
      line-height: 24px;
      text-decoration: none;
      padding-bottom: 3px;
      @media screen and (max-width: 1280px) {
        font-size: 12px;
        line-height: 0;
      }

      &:hover {
        border-bottom: 3px solid $color-red;
      }
    }
  }
}

.top-sub-links {
  width: 100%;

  & ul {
    display: flex;
    justify-content: flex-start;

    & li {
      margin-right: 20px;

      & a {
        color: $color-grey2;
        font-family: $gothamproregular;
        font-size: 13px;
        text-decoration: none;
        @media screen and (max-width: 1167px) {
          font-size: 11px;
        }

        &:hover {
          color: $color-red;
        }
      }
    }
  }
}

.top-tel-wrapper {
  flex-direction: column;

  &__row {
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.top-tel {
  width: 100%;
  align-items: center;

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;

    & svg {
      fill: $color-red;
      width: 14px;
      height: 14px;
    }
  }

  & a {
    color: $color-grey;
    font-size: 13px;
    text-decoration: none;
    font-family: $gothampromedium;

    &:hover {
      cursor: pointer;
      color: $color-red;
    }
  }
}

.top-helper-links {
  display: flex;

  & .social-links {
    margin-right: 30px;
    display: flex;

    & a {
      margin-left: 17px;
      margin-right: 0;
    }
  }
}

header.second .top-lang ul li a {
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
}

header.second .mobile-menu {
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
  }
}

.mobile-top-contacts {
  & .top-tel-wrapper {
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
    }

    &__row {
      flex-direction: column;
      line-height: 26px;
      //margin-bottom: 0;
      &:nth-of-type(1) {
        margin-top: 20px;
        margin-bottom: 0;
      }

      & .top-tel {
        justify-content: center;
      }
    }
  }
}

.mobile-header {
  display: none;
  position: relative;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 320px;
    margin: 0 auto;
    padding: 24px 9px;
  }

  & .main-header-elem {
    & .btn {
      margin-bottom: 26px;
    }
  }
}

.show-contact {
  font-size: 16px;
  font-family: $gothamproregular;
  padding-bottom: 3px;
  color: $color-red;
  border-bottom: 1px solid $color-red;
  margin-bottom: 14px;

  & span {
    &:nth-of-type(2) {
      display: none;
    }
  }

  &.active {
    color: $color-grey2;
    border-bottom: 1px solid $color-grey2;
    margin: 0 auto;

    & span {
      &:nth-of-type(1) {
        display: none;
      }

      &:nth-of-type(2) {
        display: block;
      }
    }
  }
}

.nav-icons {
  position: absolute;
  right: 12px;
  width: 34px;
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 3;

  & div {
    background-color: $color-grey;
    width: 34px;
    height: 6px;
    border-radius: 6px;

  }

  &__close {
    display: none;

    &.active {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 16px;
      width: 24px;
      height: 34px;
      z-index: 5;
    }

    & svg {
      fill: $color-red;
      width: 24px;
      height: 34px;

    }
  }
}

.mob-menu-wrapper {
  display: none;

  &.active {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    background-color: #f2f5f7;
    /* height: 100vh; */
    z-index: 4;
    width: 100vw;
    height: 100vh;
    padding-top: 28px;
    overflow-y: scroll;
    padding-bottom: 11px;
    margin-top: 0px;
  }

  & .top-menu {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;

    & li {
      margin-bottom: 19px;

      & a {
        font-size: 24px;

      }
    }
  }

  & .top-helper-links {
    flex-direction: column-reverse;

    & .social-links {
      margin-right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 215px;
      margin: 0 auto;
      & .social-links-info{
        color: $color-grey;
        font-family: $gothamproregular;
        font-size: 13px;
        text-align: center;
        margin-bottom: 20px;
      }
      & a {
        margin: 0 10px;
      }
    }

    & .top-sub-links {
      margin-bottom: 22px;

      & ul {
        display: flex;
        flex-direction: column;
        align-items: center;

        & li {
          margin-bottom: 15px;
          @media screen and (max-width: 991px) {
            margin-right: 0;
          }

          & a {
            font-size: 22px;
          }
        }
      }
    }
    & .top-lang{
      margin-top: 15px;
      display: flex;
      justify-content: center;
    }
  }
}

body {
  &.mob {
    overflow: hidden;
  }
}

.sub-mob-menu-wrapper {
  display: none;

  &.active {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    background-color: #f2f5f7;
    /* height: 100vh; */
    z-index: 4;
    width: 100vw;
    height: 100vh;
    padding-top: 90px;
    overflow-y: scroll;
    padding-bottom: 11px;
  }

  div {
    display: flex;
    flex-direction: row;

    & .top-menu {
      flex-direction: column;
      align-items: center;
      margin-bottom: 5px;

      & li {
        margin-bottom: 19px;
        text-align: left;
        width: 100%;
        padding-left: 10px;

        & a {
          font-size: 14px;
          font-family: $gothamproregular;
          padding-bottom: 4px;

          &:hover {
            padding-bottom: 4px;
            border-bottom: 2px solid $color-red;
          }
        }
      }
    }
  }


}

.back-to-menu {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-red;
  font-size: 14px;
  font-family: $gothamproregular;

  & svg {
    fill: $color-red;
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
}

.nav-icons__close {
  &.active{
    @media screen and (max-height: 320px) {
      right: -106px !important;
    }
  }
}