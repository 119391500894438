.our-service {
  background-color: #fff;
  //padding-left: 15px;
  //padding-right: 15px;
  //padding-bottom: 55px;
  position: relative;
  margin-bottom: 70px;
  margin-top: 85px;
  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 50px;
    bottom: 0;
    width: 100%;
    height: auto;
    //opacity: .9;
    //background-color: rgb(237, 237, 237);
    background-color: #f9f9f9;
  }

  @media screen and (max-width: 568px) {
    height: auto;
    padding-bottom: 0px;
    margin-bottom: 70px;
    background-position: center;
    background-size: contain;
    position: relative;
  }

  & .title {
    font-size: 27px;
    margin-bottom: 45px;
    @media screen and (max-width: 568px) {
      margin-bottom: 41px;
      font-size: 22px;
    }
  }
}
.our-service-inner{
  //background-image: url("../img/our-service-bg.png");
  background-image: url("../img/our-service-bg.png");
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 25px;
  @media screen and (max-width: 640px){
    background-position: top;
    background-size: auto;
    padding-bottom: 0;
  }
  & .btn{
    display: none;
  }
}
.our-service-list {
  padding-top: 45px;
  padding-bottom: 45px;
  @media screen and (max-width: 568px){
    padding-bottom: 105px;
  }
  & .flex {
    flex-wrap: wrap;
    justify-content: space-between;
    @media screen and (max-width: 640px){
      justify-content: space-around;
    }
    @media screen and (max-width: 414px){
      justify-content: space-around;
    }
    & .service-item-link {
      position: relative;
      padding-top: 37px;
      padding-bottom: 37px;
      margin-right: 22px;
      width: 160px;
      text-align: center;
      &::before {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 2px;
        width: 100%;
        background-color: #cbcbcb;
      }
      &::after {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background-color: #cbcbcb;
        @media screen and (max-width: 1024px){
          display: none;
        }
        @media screen and (max-width: 568px){
          display: block;
        }
      }
      @media screen and (max-width: 568px) {
        margin-bottom: 15px;
        max-width: 130px;
        width: 115px;
        margin-right: 18px;
      }
      @media screen and (max-width: 414px){
        width: 130px;
        margin-right: 0;
      }
      & a {
        text-decoration: none;
        text-transform: capitalize;
        font-size: 16px;
        text-align: justify;
        color: $color-grey;
        font-family: $gothampromedium;
        line-height: 24px;
        @media screen and (max-width: 568px) {
          font-size: 13px;
        }
      }
      &:hover {
        & a {
          color: $color-red;
          border-bottom: 1px solid $color-red;
        }
      }
      &:nth-of-type(1){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(2){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(3){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(4){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(5){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(6){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(7){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(8){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(9){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(10){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(11){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(12){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(13){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(14){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(15){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(16){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(17){
        &::after{
          display: none;
        }
      }
      &:nth-of-type(18){
        &::after{
          display: none;
        }
      }

    }
  }

}

@media screen and (max-width: 568px) {
  .service-item-link{
       display: none;
     }
  .service-item-link:nth-of-type(1){
    display: block;
  }
  .service-item-link:nth-of-type(2){
    display: block;
  }
  .service-item-link:nth-of-type(3){
    display: block;
  }
  .service-item-link:nth-of-type(4){
    display: block;
  }
  .service-item-link:nth-of-type(5){
    display: block;
    &::after{
      display: block !important;
    }
  }
  .service-item-link:nth-of-type(6){
    display: block;
    &::after{
      display: block !important;
    }
  }
  .our-service-inner .btn{
    display: block;
    position: absolute;
    bottom: -22px;
    left: 0;
    right: 0;
    max-width: 50%;
    margin: 0 auto;
  }
}