.service-heading{
  align-items: center;
  justify-content: space-between;
  margin-bottom: 46px;
  padding-left: 20px;
  padding-right: 20px;
  @media screen and (max-width: 568px){
    flex-direction: column;
  }
  & .title{
    width: auto;
    margin-bottom: 0;
    @media screen and (max-width: 568px){
      margin-bottom: 47px;
      font-size: 22px;
    }
  }
}

.service-item-content{
  & .online-registrations{
    margin-bottom: 150px;
    @media screen and (max-width: 991px){
      margin-bottom: 120px;
    }
  }
}