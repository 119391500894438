.btn {
  transition: .4s;
  cursor: pointer;
  outline: none;
  border: none;
  height: auto;
  border-radius: 11px;
  //padding: 14px 25px
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-family: $gothamproregular;
  font-size: 16px;
  @media screen and (max-width: 991px) {
    font-size: 14px;
    padding: 7px 18px;
  }
  @media screen and (max-width: 568px) {
    font-size: 14px;
    //padding: 6px 16px;
    //padding: 9px 17px;
    padding: 11px 20px;
  }

  &.red {
    border: 1px solid $color-red;
    background-color: $color-red;
    color: #fff;

    &:hover {
      border: 1px solid $color-red;
      background-color: inherit;
      color: $color-red;
      cursor: pointer;
    }

    &_inherit {
      border: 1px solid $color-red;
      background-color: inherit;
      color: $color-red;

      &:hover {
        border: 1px solid $color-red;
        background-color: $color-red;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  &.grey {
    border: 1px solid $color-grey2;
    background-color: $color-grey2;
    color: #fff;

    &:hover {
      border: 1px solid $color-grey2;
      background-color: inherit;
      color: $color-grey2;
      cursor: pointer;
    }

    &_inherit {
      border: 1px solid $color-grey2;
      background-color: inherit;
      color: $color-grey2;

      &:hover {
        border: 1px solid $color-grey2;
        background-color: inherit;
        color: $color-grey2;
        cursor: pointer;
      }
    }
    &_inherit2 {
      border: 1px solid $color-grey2;
      background-color: inherit;
      color: $color-grey2;

      &:hover {
        border: 1px solid $color-grey2;
        background-color: $color-grey2;
        color: #fff;
        cursor: pointer;
      }
    }

  }

  &.white {
    border: 1px solid #fff;
    background-color: #fff;
    color: $color-grey;

    &:hover {
      border: 1px solid inherit;
      background-color: #fff;
      color: inherit;
      cursor: pointer;
    }

    &_inherit {
      border: 1px solid #fff;
      background-color: inherit;
      color: #fff;

      &:hover {
        border: 1px solid #fff;
        background-color: #fff;
        color: $color-grey;
        cursor: pointer;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}


.reset-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: .3s;
  & span {
    &:nth-of-type(1) {
      transition: .3s;
      background-color: $color-red;
      border-radius: 50px;
      padding: 11px;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 6px;
      & svg {
        fill: #fff;
        height: 16px;
        width: 16px;
      }
    }

    &:nth-of-type(2) {
      font-family: $gothampromedium;
      font-size: 11px;
      color: $color-grey;
    }
  }
  &:hover{
    & span {
      &:nth-of-type(1) {
        background-color: $color-grey2;
      }
      &:nth-of-type(2) {
        color: $color-red;
      }
    }
  }
}