.share{
  margin-bottom: 30px;
  @media screen and (max-width: 568px){
    margin-bottom: 80px;
  }
  & .share-inner{
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    &__title{
      margin-right: 20px;
font-family: $gothamproregular;
      font-size: 14px;
      color: #9d9d9d;
    }
    &__links{
      & .share-link{
        margin-right: 10px;
        & svg{
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.back-to-list{
  & a{
    color: $color-red;
    text-decoration: none;
    font-size: 15px;
    font-family: $gothamproregular;
    &:hover{
      color: $color-grey;
    }
  }
}