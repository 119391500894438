header {
  &.home {
    //background-image: url("../img/home1.png");
    background-color: #e3e3ea;
    height: 100vh;
    max-height: 702px;
    @media screen and (max-width: 991px) {
      height: 100%;
    }
  }
}

.header-wrapper {
  position: relative;
  height: 100%;
  padding-top: 38px;
  //& .header-bg {
  //  max-width: 100%;
  //  position: absolute;
  //  margin: 0 auto;
  //  width: 100%;
  //}
  @media screen and (max-width: 991px) {
    background-image: url(../img/board-mob.jpg);
    background-repeat: no-repeat;
    background-position: center;
    height: 582px;
    padding-top: 16px;
  }
}


.top-lang {
  & ul {
    display: flex;

    & li {
      padding: 2px 0 2px 10px;

      & a {
        color: $color-grey2;
        font-size: 18px;
        font-family: $gothampromedium;
        text-transform: uppercase;
        transition: .3s;
        text-decoration: none;
        margin-right: 15px;

        &.active {
          border-bottom: 1px solid $color-grey2;
          padding-bottom: 5px;
        }

        &:hover {
          color: $color-red;
        }
      }

      &:nth-of-type(1) {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          height: 100%;
          width: 2px;
          background-color: $color-grey2;
        }
      }
    }
  }
}

.search-form {
  border-bottom: 1px solid $color-grey;
  padding-bottom: 5px;
  @media screen and (max-width: 991px) {
    margin-bottom: 24px;
  }

  & input {
    font-family: $gothamproregular;
    font-size: 13px;
    text-align: justify;
    color: $color-grey;

    &::placeholder {
      font-family: $gothamproregular;
      font-size: 13px;
      text-align: justify;
      color: $color-grey;
    }
  }

  & button {
    & svg {
      fill: $color-red;
      width: 20px;
      height: 20px;
    }
  }
}

.first-screen {
  position: relative;
  max-width: 1365px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
}

.first-screen-buttons {
  width: 949px;
  height: 788px;
  background-image: url(../img/board.png);
  background-repeat: no-repeat;
  background-size: auto;
  position: absolute;
  right: -50px;
  top: -90px;
  @media screen and (max-width: 1280px) {
    width: 816px;
  }
  @media screen and (max-width: 991px) {
    position: relative;
    left: 0;
    right: 0;
    top: initial;
    background-image: none;
    height: auto;
  }
}

.main-btns-wrapper {
  width: auto;
  height: 480px;
  padding-left: 20px;
  padding-right: 20px;
  position: absolute;
  left: 417px;
  bottom: -135px;
  @media screen and (max-width: 991px) {
    position: initial;
  }
}

.main-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 135px;
  @media screen and (max-width: 991px) {
    max-width: 147px;
    height: 80px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 168px;
    & a {
      &.btn {
        font-size: 13px;
        padding: 5px 5px;
      }
    }
  }
  @media screen and (max-width: 660px) {
    top: 185px;
  }
}


.logo {
  position: inherit;
  z-index: 2;
  @media screen and (max-width: 991px) {
    text-align: center;
    margin-bottom: 15px;
  }

}

.main-header-elem {
  position: inherit;
  z-index: 2;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 13px;
  }
}