.staff-single-info {
  @media screen and (max-width: 991px){
    max-width: 645px;
    margin: 0 auto;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 640px){
    flex-direction: column;
  }
  &__col1 {
    text-align: center;
    @media screen and (max-width: 640px){
      margin-bottom: 20px;
    }
    & .staff-photo {
      width: 298px;
      height: 392px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin-bottom: 36px;
      @media screen and (max-width: 640px){
        width: 260px;
        height: 310px;
        margin: 0 auto;
        margin-bottom: 23px;
      }
    }
    & .btn{
      width: 100%;
      @media screen and (max-width: 640px){
        max-width: 255px;
      }
    }
  }

  &__col2 {
    padding-left: 32px;
    padding-right: 65px;
    @media screen and (max-width: 640px){
      max-width: 298px;
      padding-left: 0;
      padding-right: 0;
      margin: 0 auto;
      margin-bottom: 27px;
    }
    & .staff-name {
      font-size: 16px;
      color: $color-grey;
      font-family: $gothampromedium;
      margin-bottom: 41px;
      line-height: 24px;
      text-align: left;
      @media screen and (max-width: 640px){
        text-align: center;
        margin-bottom: 21px;
      }
    }

    & .staff-legend {
      font-size: 15px;
      color: $color-grey;
      font-family: $gothamproregular;
      line-height: 22px;
      margin-bottom: 57px;
      text-align: left;
      @media screen and (max-width: 640px){
        text-align: center;
        margin-bottom: 34px;
      }
    }

    &.staff-item__tags {

    }
  }
}