.ui.rating {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 20px;
}

.ui.large.rating {
  //font-size: 1.14285714rem;
  margin-left: 7px;
}

i.icon {
  display: inline-block;
  opacity: 1;
  margin: 0 .25rem 0 0;
  width: 25px;
  height: 25px;
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  text-align: center;
  speak: none;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
i.icon, i.icons {
  font-size: 25px;
}
.ui.rating .icon {
  padding: 0;
  margin: 0;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  cursor: pointer;
  width: 20px;
  height: auto;
  -webkit-transition: opacity .1s ease,background .1s ease,text-shadow .1s ease,color .1s ease;
  transition: opacity .1s ease,background .1s ease,text-shadow .1s ease,color .1s ease;
  background: 0 0;
  color: rgba(0,0,0,.15);
  font-family: Rating;
  line-height: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  font-weight: 400;
  font-style: normal;
  text-align: center;
}
.ui.heart.rating .icon {
  width: 25px;
  height: 25px;
  background: 0 0;
  color: rgba(0,0,0,0);
  text-shadow: none!important;
}
i.icon:before {
  background: 0 0!important;
}
.ui.rating .active.icon:before, .ui.rating .icon:before, .ui.star.rating .active.icon:before, .ui.star.rating .icon:before {
  content: '\f005';
}
.ui.heart.rating .active.icon:before, .ui.heart.rating .icon:before {
  content: '\f004';
}

.ui.heart.rating .icon:before{
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMiA1MTI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNFMzFFMjQ7fQ0KPC9zdHlsZT4NCjxnPg0KCTxnPg0KCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNDc0LjYsNzQuM2MtMjUuMy0yOC43LTYwLjMtNDQuNC05OC42LTQ0LjRjLTUzLjksMC04OC4xLDMyLjItMTA3LjMsNTkuM2MtNSw3LTkuMiwxNC0xMi43LDIwLjcNCgkJCWMtMy41LTYuNi03LjgtMTMuNy0xMi43LTIwLjdjLTE5LjItMjctNTMuMy01OS4zLTEwNy4zLTU5LjNjLTM4LjQsMC03My40LDE1LjgtOTguNiw0NC40QzEzLjMsMTAxLjYsMCwxMzguMiwwLDE3Ny40DQoJCQljMCw0Mi42LDE2LjYsODIuMiw1Mi4zLDEyNC43YzMxLjksMzgsNzcuOCw3Ny4xLDEzMSwxMjIuNGMxOS44LDE2LjksNDAuMywzNC4zLDYyLjEsNTMuNGwwLjcsMC42YzIuOCwyLjUsNi40LDMuNyw5LjksMy43DQoJCQljMy41LDAsNy4xLTEuMiw5LjktMy43bDAuNy0wLjZjMjEuOC0xOS4xLDQyLjMtMzYuNSw2Mi4xLTUzLjRjNTMuMi00NS4zLDk5LjEtODQuNCwxMzEtMTIyLjRDNDk1LjQsMjU5LjYsNTEyLDIyMCw1MTIsMTc3LjQNCgkJCUM1MTIsMTM4LjIsNDk4LjcsMTAxLjYsNDc0LjYsNzQuM3ogTTMwOS4yLDQwMS42Yy0xNy4xLDE0LjYtMzQuNywyOS41LTUzLjIsNDUuNmMtMTguNS0xNi4xLTM2LjEtMzEuMS01My4yLTQ1LjYNCgkJCUM5OC43LDMxMi45LDMwLDI1NC40LDMwLDE3Ny40YzAtMzEuOCwxMC42LTYxLjQsMjkuOS04My4yQzc5LjMsNzIsMTA2LjQsNTkuOCwxMzYsNTkuOGM0MS4xLDAsNjcuNywyNS4zLDgyLjgsNDYuNg0KCQkJYzEzLjUsMTkuMSwyMC42LDM4LjMsMjMsNDUuN2MyLDYuMiw3LjgsMTAuNCwxNC4zLDEwLjRzMTIuMy00LjIsMTQuMy0xMC40YzIuNC03LjQsOS41LTI2LjYsMjMtNDUuNw0KCQkJYzE1LjEtMjEuMyw0MS42LTQ2LjYsODIuOC00Ni42YzI5LjYsMCw1Ni43LDEyLjIsNzYuMSwzNC4zYzE5LjMsMjEuOSwyOS45LDUxLjQsMjkuOSw4My4yQzQ4MiwyNTQuNCw0MTMuMywzMTIuOSwzMDkuMiw0MDEuNnoiLz4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4NCg==') !important;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.ui.heart.rating .icon.selected:before{
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUxMCA1MTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMCA1MTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNFMzFFMjQ7fQ0KPC9zdHlsZT4NCjxnPg0KCTxnIGlkPSJmYXZvcml0ZSI+DQoJCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yNTUsNDg5LjZsLTM1LjctMzUuN0M4Ni43LDMzNi42LDAsMjU3LjUsMCwxNjAuNmMwLTc5LDYxLjItMTQwLjMsMTQwLjMtMTQwLjNjNDMuNCwwLDg2LjcsMjAuNCwxMTQuOCw1My41DQoJCQljMjgtMzMuMSw3MS40LTUzLjUsMTE0LjgtNTMuNWM3OSwwLDE0MC4zLDYxLjIsMTQwLjMsMTQwLjNjMCw5Ni45LTg2LjcsMTc1LjktMjE5LjMsMjkzLjNMMjU1LDQ4OS42eiIvPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K') !important;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.ui.heart.rating .icon.active:before{
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDUxMCA1MTAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMCA1MTA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNFMzFFMjQ7fQ0KPC9zdHlsZT4NCjxnPg0KCTxnIGlkPSJmYXZvcml0ZSI+DQoJCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yNTUsNDg5LjZsLTM1LjctMzUuN0M4Ni43LDMzNi42LDAsMjU3LjUsMCwxNjAuNmMwLTc5LDYxLjItMTQwLjMsMTQwLjMtMTQwLjNjNDMuNCwwLDg2LjcsMjAuNCwxMTQuOCw1My41DQoJCQljMjgtMzMuMSw3MS40LTUzLjUsMTE0LjgtNTMuNWM3OSwwLDE0MC4zLDYxLjIsMTQwLjMsMTQwLjNjMCw5Ni45LTg2LjcsMTc1LjktMjE5LjMsMjkzLjNMMjU1LDQ4OS42eiIvPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K') !important;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.rating-wrapper{
  color: $color-grey;
  font-size: 17px;
  font-family: $gothamproregular;
  margin-bottom: 20px;
  @media screen and (max-width: 768px){
    font-size: 13px;
  }
  @media screen and (max-width: 420px){
    font-size: 13px;
  }
}