.question-item {
  width: 370px;
  background-color: #fff;
  padding-top: 52px;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 42px;

  @media screen and (max-width: 991px) {
    max-width: initial;
  }
  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
  @media screen and (max-width: 568px) {
    max-width: 290px;
    margin: 0 auto;
    width: 100%;
    padding: 52px 10px 42px;
  }

  & .question-item-inner {
    & a {
      text-decoration: none;
    }

    & .shadow-box {
      //box-shadow: 0px 7px 20px -5px rgba(0, 0, 0, 0.26);
      box-shadow: -7px 4px 20px -5px rgba(0, 0, 0, .26);
      transition: .1s;
      @media screen and (max-width: 1024px) {
        overflow: hidden;
        margin-bottom: 37px;
      }
    }
  }

  .question-item-heading {
    &__title {
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 11px;

      & .icon {
        margin-right: 7px;

        & svg {
          fill: #e31e24;
          width: 32px;
          height: 28px;
        }
      }

      & .name {
        font-size: 17px;
        font-family: $gothampromedium;
        color: $color-grey;
      }

      & .rating {
        & svg {
          fill: $color-grey2;
          height: 13px;
          width: 12px;
          margin-right: 3px;
        }
      }
    }

    &__meta {
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 32px;
      margin-left: 47px;

      & div {
        &:nth-of-type(1) {
          color: $color-red;
          font-size: 10px;
          font-family: $gothampromedium;
        }

        &:nth-of-type(2) {
          display: flex;
          align-items: center;

          & span {
            margin-right: 10px;

            &:nth-of-type(1) {
              & svg {
                fill: $color-grey;
                width: 14px;
                height: 14px;
              }
            }

            &:nth-of-type(2) {
              font-size: 10px;
              text-align: left;
              color: $color-grey;
              font-family: $gothampromedium;
            }
          }
        }
      }
    }
  }


  &__tags {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media screen and (max-width: 568px) {

    }

    & .question-tag {
      padding: 7px 10px;
      opacity: 0.26;
      border-radius: 15px;
      background-color: $color-grey2;
      font-size: 13px;
      border: solid 1px $color-grey2;
      font-family: $gothampromedium;
      color: #fff;
      line-height: 16px;
      text-align: center;
      margin-right: 10px;
      margin-bottom: 10px;
      @media screen and (max-width: 568px) {
        font-size: 12px;
        padding: 7px 8px;
      }

      &:hover {
        opacity: 0.8;
        border-radius: 15px;
        color: $color-grey2;
        border: solid 1px $color-grey2;
        background-color: #fff;
      }
    }
  }

  &__answer {
    display: none;
    font-family: $gothamproregular;
    font-size: 13px;
    color: $color-grey;
    padding-bottom: 20px;
    margin-top: 15px;
  }

  &:hover {
    & .shadow-box {
      box-shadow: -4px -2px 20px -5px rgba(0, 0, 0, .26);
    }
  }

  &__content {
    position: relative;
    font-size: 14px;
    color: $color-grey;
    font-family: $gothamproregular;
    margin-bottom: 20px;
    line-height: 24px;

    & .readmore__hide {
      line-height: 22px;
      //margin-bottom: 30px;
      margin-bottom: 0;
      height: 116px;
      overflow: hidden;
      color: $color-grey;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 4em;
        background: #fff;
        background: linear-gradient(0deg, rgba(255, 255, 255, .798757) 0, rgba(255, 255, 255, .01) 100%);
      }

      &.active {
        height: auto;
        @media screen and (max-width: 768px) {
          font-size: 13px;
          line-height: 24px;
        }

        &::before {
          display: none;
        }
      }

      @media screen and (max-width: 768px) {
        font-size: 13px;
        line-height: 24px;
      }
    }

    & .readmore__link {
      float: left;
      font-size: 11px;
      display: block;
      color: $color-grey;
      border-bottom: 1px solid $color-grey;
      padding-bottom: 2px;
      margin-bottom: 35px;
      width: max-content;
      margin-top: 25px;

      &:hover {
        cursor: pointer;
      }
    }

    & .readmore-comment {
      margin-top: 25px;
      margin-bottom: 20px;
      float: right;
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        margin-bottom: 40px;
        margin-top: 0;
      }

      &:hover {
        cursor: pointer;
      }

      & svg {
        fill: $color-grey;
        width: 13px;
        height: 13px;
        transition: .2s;
      }

      & span {
        font-size: 11px;
        display: block;
        color: #555;
        border-bottom: 1px solid #555;
        padding-bottom: 2px;
        margin-left: 5px;
        width: max-content;

        &:nth-of-type(2) {
          display: none;
        }
      }

      &.active {

      }
    }

    & .add-comment {
      float: left;
      font-size: 11px;
      display: block;
      color: #555;
      border-bottom: 1px solid #555;
      padding-bottom: 2px;
      margin-bottom: 35px;
      width: max-content;
      margin-left: 15px;

      &:hover {
        cursor: pointer;
      }

      @media screen and (max-width: 568px) {
        float: right;
      }
    }
  }
}


.question-item-inner {
  &.active {
    & .question-item__answer {
      display: block;

      & .doctor-wrapper {
        display: flex;
        margin-bottom: 16px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: center;
        }

        & img {
          max-width: 100%;
          height: 175px !important;
          margin-right: 30px;
          @media screen and (max-width: 768px) {
            margin-bottom: 15px;
            margin-right: 0;
          }
        }

        & .doctor-info {
          & .doctor-name {
            font-family: $gothampromedium;
            font-size: 13px;
            color: $color-grey;
            margin-bottom: 10px;
            text-align: left;
            @media screen and (max-width: 768px) {
              text-align: center;
            }
          }

          & .doctor-position {
            font-family: $gothamproregular;
            font-size: 13px;
            color: $color-grey;
            text-align: left;
            margin-bottom: 21px;
            @media screen and (max-width: 768px) {
              text-align: center;
              line-height: 21px;
            }
          }
        }
      }
    }

    & .readmore-comment {
      & span {
        &:nth-of-type(1) {
          display: none;
        }

        &:nth-of-type(2) {
          display: block;
        }
      }

      & svg {
        transform: rotate(180deg);
      }
    }
  }
}

.doctor-answer {
  line-height: 22px;
}


.questions-carousel {
  & .question {
    & .question-item-inner {
      &.active {
        & .question-item__answer {
          & .doctor-wrapper{
            & .answer-staff{
              display: flex;
              @media screen and (max-width: 568px){
                flex-direction: column;
                align-items: center;
              }
              & img {
                max-width: 146px !important;
                height: auto !important;
              }
            }
            & .doctor-info{
              & .doctor-name {
                text-align: left;
                @media screen and (max-width: 568px){
                  text-align: center;
                }
              }

              & .doctor-position {
                text-align: left;
                @media screen and (max-width: 568px){
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
    & .question-item{
      & .readmore-comment{
        @media screen and (max-width: 568px){
          margin-top: 25px;
        }
      }
    }
  }

}