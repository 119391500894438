.news-section {
  & .news-item {
    margin-bottom: 130px;

    &__text {
      line-height: 18px;
    }

    &__description {
      margin-bottom: 0 !important;
    }

    &__date {
      margin-bottom: 8px;
    }

    &__tags {
      & .news-tag {
        font-family: $gothamproregular;
      }
    }

    & .news-item-inner {
      &:hover {
        & .news-item__title {
          color: $color-red;
        }
      }
    }
  }
}


.main-news {
  margin-bottom: 150px;
  @media screen and (max-width: 1170px) {

  }

  & .news-item {
    max-width: 870px;

    & .news-item-inner {
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      @media screen and (max-width: 1170px) {
        flex-direction: column !important;
      }

      & a {
        margin-right: 0;
      }

      & .news-item__image {
        width: 490px;
        height: 370px;
      }

      & .news-item__tags {
        & .news-tag {
          font-size: 13px;
          font-family: $gothamproregular;
        }
      }

      & .main-news-info {
        //margin-left: 25px;
        margin-left: 0;
        margin-bottom: 30px;
        @media screen and (max-width: 1170px) {
          margin-left: 0;
        }

        & .news-item__description {
          padding-top: 0;

          & .news-item__title {
            font-size: 27px;
            font-family: $gothampromedium;
            margin-bottom: 28px;
            @media screen and (max-width: 568px) {
              text-align: center;
            }

            & a {
              text-decoration: none;
              color: $color-grey;
              @media screen and (max-width: 568px) {
                font-size: 22px;
              }

              &:hover {
                color: $color-red;
              }
            }
          }
        }

        & .news-item__text {
          font-size: 15px;
          font-family: $gothamproregular;
          line-height: 22px;
          margin-bottom: 28px;
          @media screen and (max-width: 568px) {
            height: auto;
          }
        }

        & .news-item__date {
          font-size: 14px;
          font-family: $gothamproregular;
          margin-bottom: 15px;
        }
      }

    }
  }
}

.news-section {
  & .row {
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 991px) {
  .main-news .news-item .news-item-inner .news-item__image {
    margin: 0 auto;
  }
  .main-news .news-item .news-item-inner a {
    margin-bottom: 20px;
  }
  .main-news .news-item .news-item-inner .main-news-info {
    max-width: 490px;
    margin: 0 auto;
  }
  .news-section .news-item {
    max-width: 100%;
    margin-bottom: 70px;
  }
  .news-section .news-item__text {
    height: auto;
  }
}

@media screen and (max-width: 568px) {
  .main-news .news-item .news-item-inner .news-item__image {
    width: 100%;
    max-height: 267px;
    height: 267px;;
  }
}

.single-image {
  max-width: 870px;
  margin-bottom: 72px;
}

.single-news-title {
  & .news-item__image {
    width: 100% !important;
    max-width: 770px;
    overflow: hidden !important;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: auto;
      height: 100%;
      @media screen and (max-width: 1170px){
        width: 100%;
        height: auto;
      }
    }
  }

  & .news-item__title {
    font-size: 27px;
    margin-bottom: 0 !important;
  }

  & .main-news {
    & .news-item {
      width: 100%;
      max-width: initial;
    }
  }
}

.single-news-title .main-news {
  margin-bottom: 0px;
}

@media screen and (max-width: 1170px) {
  .single-news-title .main-news {
    margin-bottom: 0;
  }
  .single-news-title .main-news .news-item .news-item-inner {
    flex-direction: row;
    justify-content: flex-start;
  }
  .single-news-title .main-news .news-item .news-item-inner .main-news-info {
    margin-left: 25px;
  }
  .single-news-title .main-news .news-item .news-item-inner .news-item__image {
    max-width: 550px;
  }
  .single-image {
    max-width: inherit;
    width: 100%;
  }
  .single-content .carousel-owlc {
    margin-bottom: 20px;
  }
  .single-content .text_justify {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 991px) {
  .single-news-title .main-news .news-item .news-item-inner {
    flex-direction: column;
  }
  .single-news-title .main-news .news-item .news-item-inner .main-news-info {
    margin: 0 auto;
    margin-left: 0;
    max-width: 500px;

  }
  .single-news-title .main-news .news-item .news-item-inner .news-item__image {
    margin-bottom: 20px;
  }

}

@media screen and (max-width: 568px) {
  .single-news-title .main-news .news-item .news-item-inner .news-item__image {
    height: 274px;
  }
  .single-news-title .news-item__title {
    font-size: 22px;
  }
  .single-news-title .main-news .news-item .news-item-inner .news-item__tags .news-tag {
    font-size: 12px;
    margin-bottom: 5px;
  }
  .single-news-title .main-news .news-item {
    margin-bottom: 40px;
  }
  .single-content .carousel-owlc__wrapper {
    margin-bottom: 0;
  }
}


.blog-wrapper {
  @media screen and (max-width: 568px) {
    position: relative;
    & .main-news {
      position: absolute;
      top: 0;
      left: 0;

      & .main-news-info {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .news-section {
      margin-top: 650px;
    }
  }
}


@import "post-gallery.scss";

.single-news-intro {
  @media screen and (max-width: 568px) {
    padding-right: 0;
    padding-left: 0;
    overflow: hidden;
    & .main-news-info {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.single-content {
  & .carousel-owlc {
    margin-bottom: 60px;
    margin-bottom: 60px;
  }
}

.single-image-wrapper {
  margin-bottom: 72px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @media screen and (max-width: 568px) {
    height: 246px;
    background-position: top;
    background-size: cover;
    & .single-image {

    }
  }
}

.single-content {
  @media screen and (max-width: 568px) {
    & .container {
      padding-right: 0;
      padding-left: 0;
      overflow: hidden;

      & .pd-10 {
        padding-left: 10px;
        padding-right: 10px;
      }

      & .m-10 {
        margin-right: 10px;
        margin-left: 10px;
        padding-bottom: 20px;
      }
    }
  }
}

.news-item-inner.single-post {
  display: flex;
  flex-direction: row !important;
}

.single-news-title {
  display: flex;
  justify-content: space-between;
  & .sidebar-subscribe {
    max-width: 262px;
  }
}

.news-item-inner.single-post .main-news-link{
  margin-right: 20px !important;
  @media screen and (max-width: 1170px){
    margin-right: 0 !important;
  }
}

.blog-wrapper .sidebar-subscribe {
  margin-top: 530px;
  @media screen and (max-width: 1024px){
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px){

}