footer {
  &.secondary {
    padding-top: 40px;
    padding-bottom: 46px;
    border-top: 2px solid #999999;
    margin-left: 35px;
    margin-right: 35px;
    @media screen and (max-width: 767px) {
      margin-left: 0px;
      margin-right: 0px;
    }

    & .copyright {
      color: #bababa;
      font-size: 13px;
      line-height: 21px;
      @media screen and (max-width: 810px) {
        margin-top: 60px;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
        margin-top: 39px;
      }

      & a {
        color: #bababa;
        font-size: 13px;
        text-decoration: none;

        &:hover {
          color: $color-grey;
        }
      }
    }

    & .footer-btns {
      text-align: center;
      height: 110px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: 767px){
        margin-bottom: 20px;
      }
      & .btn {
        margin: 0 auto;
        width: 200px;
        padding: 10px 14px;
        font-size: 15px;
        text-transform: uppercase;
      }
    }

    & .logo {
      margin-bottom: 12px;
      @media screen and (max-width: 991px) {
        text-align: left;
      }
      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }

    & .social-links{
      @media screen and (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        & span{
          &:nth-of-type(1){
            margin-bottom: 15px;
          }
          &:nth-of-type(2){
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 80px;
            & a{
              margin-right: 0;
              margin-left: 0;
            }
          }
        }
      }
    }

    & .footer-menu {
      margin-bottom: 35px;

      & ul {
        @media screen and (max-width: 767px) {
          justify-content: center;
        }

        & li {
          position: relative;
          padding-left: 11px;
          padding-right: 11px;

          & a {
            color: $color-grey2;
            font-size: 16px;
            font-family: $gothampromedium;
            text-transform: capitalize;
            transition: .3s;
            text-decoration: none;
            @media screen and (max-width: 1200px){
              font-size: 13px;
            }

            @media screen and (max-width: 991px) {
              font-size: 11px;
            }

            &.active {
              border-bottom: 1px solid $color-grey2;
              padding-bottom: 5px;
            }

            &:hover {
              color: $color-red;
            }
          }

          &::after {
            content: "";
            position: absolute;
            right: 0;
            height: 100%;
            width: 2px;
            background-color: $color-grey2;
          }

          &:first-of-type {
            padding-left: 0;
          }

          &:last-of-type {
            &::after {
              display: none;
            }
          }
        }
      }
    }

    & .social-links {
      color: #bababa;
      font-family: $gothampromedium;
      font-size: 13px;
      margin-bottom: 19px;

      & a {
        margin-left: 15px;
        margin-right: 15px;
        text-transform: none;
        text-decoration: none;

        & svg {
          fill: #bababa;
          width: 18px;
          height: 18px;
          transition: .3s;
        }

        &:hover {
          & svg {
            fill: $color-grey;
          }
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }


    & .footer-social {
      text-align: right;
      @media screen and (max-width: 991px){
        text-align: center;
      }
      @media screen and (max-width: 768px){
        margin-top: 30px;
      }
      @media screen and (max-width: 568px){

      }
      & > a {
        color: #1d1d1d;
        font-family: $gothampromedium;
        font-size: 18px;
        text-decoration: none;
        margin-bottom: 13px;
        display: block;

        &:hover {
          color: $color-red;
        }

      }

      & .red {
        text-transform: uppercase;
        @media screen and (max-width: 767px) {
          font-size: 14px;
          padding: 10px 20px;
        }
      }

      & .footer-tel {
        width: 100%;
        justify-content: flex-end;
        text-align: right;
        @media screen and (max-width: 991px){
          justify-content: center;
          text-align: center;
        }
        & span {
          margin-right: 13px;
          & svg {
            fill: $color-red;
            width: 18px;
            height: 18px;
          }
        }

        & > a {
          color: #1d1d1d;
          font-family: $gothampromedium;
          font-size: 22px;
          text-decoration: none;
          margin-bottom: 9px;
          display: block;

          &:hover {
            color: $color-red;
          }
          &   span{
            color: $color-red;
          }
        }
      }
    }

  }
}

