.filter-selection{
  margin-bottom: 62px;
  &__inner{
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1070px){
      flex-direction: column;
      align-items: center;
      & .form-group{
        margin-bottom: 42px !important;
        &:nth-of-type(1){
          margin-bottom: 50px !important;
        }
        &:nth-of-type(4){
          margin-bottom: 30px !important;
        }
        & label{
          margin-left: 20px;
        }
      }
    }
  }
}


@media screen and (max-width: 991px){

  .filter-selection__inner .mr-md-5{
    margin-right: 0 !important;
  }
}


