
.content {
  padding-top: 85px;
  //padding-left: 10px;
  //padding-right: 10px;
  padding-bottom: 80px;
  @media screen and (max-width: 991px) {
    padding: 54px 0px 50px 0px;
  }
  @media screen and (max-width: 991px) {
    padding-bottom: 10px;
  }
  //&_pt-0{
  //  padding-top: 45px;
  //}
}

.title {
  font-family: $gothampromedium;
  font-size: 27px;
  line-height: 24px;
  color: $color-grey;
  margin-bottom: 43px;
  text-align: center;
  width: 100%;
  @media screen and (max-width: 991px) {
    font-size: 22px;
    line-height: 28px;
  }

  &_single {
    text-align: left;
  }
}

.text {
  & p {
    font-family: $gothamproregular;
    font-size: 15px;
    color: $color-grey;
    line-height: 24px;
    text-align: justify;
    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
  }

  &_left {
    text-align: left !important;
  }

  &_justify {
    text-align: justify !important;
  }
}

.marked-list {
  max-width: 580px;
  margin: 0 auto;

  & ul {
    & li {
      font-family: $gothamproregular;
      font-size: 15px;
      color: $color-grey;
      position: relative;
      line-height: 24px;
      margin-left: 40px;
      margin-bottom: 7px;

      &::before {
        content: "";
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: $color-red;
        position: absolute;
        left: -29px;
        top: 5px;
      }

      & a {
        font-family: $gothamproregular;
        font-size: 15px;
        color: $color-grey;
        line-height: 24px;
        text-decoration: none;
      }
    }
  }
}


//SEO TEXT EXPAND

.readmore {
  position: relative;
}

.readmore__hide {
  position: relative;

  transition: all .5s ease-in-out;
  //transition: .3s;
  //max-width: 1040px;
  max-width: 100%;
  margin: 0 auto;
  font-size: 15px;
  line-height: 24px;
  //color: $color-red;
  font-family: $gothamproregular;

  margin-bottom: 22px;
  @media screen and (max-width: 991px) {
    max-height: 230px;
    overflow: hidden;
  }
}

.readmore__hide:before {
  display: none;
  @media screen and (max-width: 991px) {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 4em;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7987570028011204) 0%, rgba(255, 255, 255, 0.01) 100%);
  }

}

.readmore__hide.active {
  //max-height: 5000px;
  max-height: initial;
  overflow: inherit;
}

.readmore__hide.active:before {
  display: none;
}

.readmore__link {
  display: none;
  @media screen and (max-width: 991px) {
    display: block;
    margin: 0 auto;
    color: $color-red;
    font-size: .875rem;
    border-bottom: 1px solid $color-red;
    padding-bottom: 0;
    max-width: max-content;
    transition: .3s;
    text-align: center;
    width: 100%;
    &:hover {
      color: $color-red;
      cursor: pointer;
    }
  }
}

.readmore__link span:last-of-type {
  display: none;
}

.active + .readmore__link span:first-of-type {
  display: none;
}

.active + .readmore__link span:last-of-type {
  display: inline;
}


.text {
  font-size: 15px;
  font-family: $gothamproregular;
  color: $color-grey;
  line-height: 24px;
  text-align: center;
  margin-bottom: 140px;
  padding: 10px 7px;
  @media screen and (max-width: 568px) {
    margin-bottom: 40px;
  }
}


.advantages_bg {
  margin-top: 57px;
  margin-bottom: 70px;
  background-repeat: no-repeat;
  padding-top: 67px;
  padding-bottom: 57px;
  background-size: contain;
  background-position: left;
  width: 100%;

  & .advantages-list {
    //max-width: 592px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }
}

.text-marked {
  @media screen and (max-width: 991px) {
    margin-bottom: 70px;
  }
}

.attention {
  margin-top: 20px;
  margin-bottom: 50px;

  & .attention-inner {
    background-image: url("../img/attention-bg.jpg");
    background-repeat: no-repeat;
    background-position: left;
    border-radius: 12px;
    border: 1px solid #bababa;
    padding: 16px 20px 23px 20px;

    & .title {
      color: $color-red;
      font-family: $gothampromedium;
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 10px;
      text-align: left;
    }

    & .text {
      font-size: 15px;
      line-height: 24px;
      color: $color-grey;
      font-family: $gothamproregular;
      margin-bottom: 0;
      text-align: left;
      padding: 0;
    }
  }
}


//SEO DESKTOP

.seo-text {
  //SEO TEXT EXPAND
  @media screen and (max-width: 1280px) {
    margin-bottom: 10px;
  }

  & .seo-readmore {
    position: relative;
  }

  & .seo-readmore__hide {
    position: relative;

    transition: all .5s ease-in-out;
    //transition: .3s;
    //max-width: 1040px;
    max-width: 100%;
    margin: 0 auto;
    color: $color-red;
    font-family: $gothamproregular;
    font-size: 14px;
    margin-bottom: 22px;
    max-height: 230px;
    overflow: hidden;
    @media screen and (max-width: 991px) {
    }
  }

  & .seo-readmore__hide:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 4em;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7987570028011204) 0%, rgba(255, 255, 255, 0.01) 100%);


  }

  & .seo-readmore__hide.active {
    //max-height: 5000px;
    max-height: initial;
    overflow: inherit;
  }

  & .seo-readmore__hide.active:before {
    display: none;
  }

  & .seo-readmore__link {
    display: block;
    margin: 0 auto;
    color: $color-red;
    font-size: .875rem;
    border-bottom: 1px solid $color-red;
    padding-bottom: 0;
    max-width: max-content;
    transition: .3s;
    text-align: center;
    width: 100%;

    &:hover {
      color: $color-red;
      cursor: pointer;
    }
  }

  & .seo-readmore__link span:last-of-type {
    display: none;
  }

  & .active + .seo-readmore__link span:first-of-type {
    display: none;
  }

  & .active + .seo-readmore__link span:last-of-type {
    display: inline;
  }
}

.text.seo-text{
  margin-bottom: 0;
}

.subvision{
  //padding-top: 155px;
  @media screen and (max-width: 991px){
    padding-top: 0;
  }
  & .breadcrumbs{
    padding-top: 0;
  }
}