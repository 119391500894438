.side-wrapper{
  position: relative;
  top: -60px;
  @media screen and (max-width: 991px){
    position: initial;
  }
  & .title {
    font-size: 20px;
    margin-bottom: 55px;
    @media screen and (max-width: 991px){
      margin-bottom: 22px;
    }
  }
}
.sidebar-accordion {
  width: 300px;
  box-shadow: 0px 3px 9.3px 0.7px rgba(0, 0, 0, 0.13);
  padding: 27px 40px 35px 27px;
  @media screen and (max-width: 1280px){
    max-width: 300px;
    width: 100%;
  }
  @media screen and (max-width: 991px){
    max-width: 640px;
    margin: 0 auto;
    margin-bottom: 70px;
  }
  @media screen and (max-width: 640px){
    max-width: 100%;
    margin: 0 auto 53px;
    width: auto;
    padding: 27px 12px 35px 27px;
  }


  &__inner {
    & .accordion-item{
      margin-bottom: 14px;
      & .accordion-toggle-title {
        position: relative;
        width: 100%;
        display: block;
        color: #bababa;
        font-family: $gothamproregular;
        font-size: 15px;
        transition: background .3s ease;
        margin-bottom: 13px;
        &:hover {
          color: $color-grey;
          cursor: pointer;
          font-family: $gothampromedium;
        }

        &.active {
          color: $color-grey;
          font-family: $gothampromedium;

          &::after {
            transform: rotate(-90deg);
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 3px;
          width: 10px;
          height: 10px;
          background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNDUxLjg0NyA0NTEuODQ3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NTEuODQ3IDQ1MS44NDc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48Zz4KCTxwYXRoIGQ9Ik0yMjUuOTIzLDM1NC43MDZjLTguMDk4LDAtMTYuMTk1LTMuMDkyLTIyLjM2OS05LjI2M0w5LjI3LDE1MS4xNTdjLTEyLjM1OS0xMi4zNTktMTIuMzU5LTMyLjM5NywwLTQ0Ljc1MSAgIGMxMi4zNTQtMTIuMzU0LDMyLjM4OC0xMi4zNTQsNDQuNzQ4LDBsMTcxLjkwNSwxNzEuOTE1bDE3MS45MDYtMTcxLjkwOWMxMi4zNTktMTIuMzU0LDMyLjM5MS0xMi4zNTQsNDQuNzQ0LDAgICBjMTIuMzY1LDEyLjM1NCwxMi4zNjUsMzIuMzkyLDAsNDQuNzUxTDI0OC4yOTIsMzQ1LjQ0OUMyNDIuMTE1LDM1MS42MjEsMjM0LjAxOCwzNTQuNzA2LDIyNS45MjMsMzU0LjcwNnoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiIGZpbGw9IiNFMzFFMjQiLz4KPC9nPjwvZz4gPC9zdmc+Cg==');
          background-repeat: no-repeat;
          background-size: cover;
          transition: .3s;
        }
      }

      & .accordion-inner {
        display: none;
        margin-left: 30px;
        & li{
          margin-bottom: 10px;

          & a{
            font-size: 13px;
            font-family: $gothamproregular;
            color: $color-grey;
            padding-bottom: 2px;
          }
          &:hover{
            & a{
              color: $color-red;
              padding-bottom: 2px;
              border-bottom: 1px solid $color-red;
            }
          }
        }
        &.show {
          display: none;
          padding-bottom: 5px;
        }
      }
    }
  }
}



.mobile-visible{
  display: none;
  @media screen and (max-width: 991px){
    display: block;
  }
}
.desktop-visible{
  @media screen and (max-width: 991px){
    display: none;
  }
}