@import "popup_callback.scss";
@import "popup_doctor.scss";
@import "popup_review.scss";
@import "popup_ask.scss";
@import "popup_center-health.scss";
@import "popup_doctor-direction.scss";
@import "popup_doctor-service.scss";
@import "popup_price-service.scss";

//Общие стили для вызова модала
.cd-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(56, 57, 58, .9);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
}

.cd-popup-container {
  position: relative;
  margin: 7em auto;
  //width: 475px;
  max-height: 65vh;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: .3s;
  -moz-transition-duration: .3s;
  transition-duration: .3s;
}

@media only screen and (max-width: 768px) {
.cd-popup-container {
  margin: 3em auto;
}

}

.callback-form {
  //background-color: #fff;
  padding: 0;
  border-radius: 0;
  z-index: 100;
}

.cd-popup-container .cd-popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 26px;
  height: 26px;
}

.cd-popup-container .cd-popup-close:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 26px;
  height: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDQ4IDQ4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OCA0ODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0UzMUUyNDt9DQo8L3N0eWxlPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTI4LjIsMjRMNDcuMSw1LjFjMS4yLTEuMiwxLjItMy4xLDAtNC4yYy0xLjItMS4yLTMuMS0xLjItNC4yLDBMMjQsMTkuN0w1LjEsMC45Yy0xLjItMS4yLTMuMS0xLjItNC4yLDANCgkJYy0xLjIsMS4yLTEuMiwzLjEsMCw0LjJMMTkuNywyNEwwLjksNDIuOGMtMS4yLDEuMi0xLjIsMy4xLDAsNC4yQzEuNSw0Ny43LDIuMiw0OCwzLDQ4czEuNS0wLjMsMi4xLTAuOUwyNCwyOC4ybDE4LjksMTguOQ0KCQljMC42LDAuNiwxLjQsMC45LDIuMSwwLjlzMS41LTAuMywyLjEtMC45YzEuMi0xLjIsMS4yLTMuMSwwLTQuMkwyOC4yLDI0eiIvPg0KPC9nPg0KPC9zdmc+DQo=');
}

.cd-popup.is-visible {
  z-index: 9;
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}

.is-visible .cd-popup-container {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}






@media screen and (max-width: 660px){
  body .cd-popup_doctor .form-row .form-group input{
    text-align: left !important;
    padding-left: 11px!important;
  }
  .online-registrations .reg-form .form-row .form-group .picker.time input{
    text-align: left !important;
    padding-left: 11px!important;
  }
}
