*, *:before, *:after {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

svg {
  font-style: normal;
}

body, input, button, textarea, select {
  outline: none !important;
  box-shadow: none;
  border: 0;
  padding: 0;
  background: none;
  margin: 0;
  /* @include font-size($main-text-size);*/
  //font-family: $font-regular;
  line-height: 1.1;
  //color: $color-text-main;
}

button, a, label, select, input[type=submit] {
  cursor: pointer;
}

body {
  /*  overflow-x: hidden;*/
  //color: $color-text-main;
}

a {
  //color: $color-blue;
  transition: .4s;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

b {
  font-weight: normal;
  /* @include font(bold);*/
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

/*h1 {
  margin: 0;
  @include font-size(40);
  font-weight:inherit;
  @include media(max, xs) {
    @include font-size(30);
  }
}
h2 {
  @include font-size(18);
}
h3 {
  @include font-size(14);
}*/
.no-pad {
  padding: 0 0;
}

p {
  text-indent: rem(40);
  color: $color-grey;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  color: #555;
  font-family: GothamProMedium;
  text-align: center;
}

.section {
  padding-top: 3rem;
  padding-right: 0px;
  padding-bottom: 3rem;
  padding-left: 0px;
}

.container {
  max-width: 1170px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.flex {
  display: flex;

  &.sb {
    justify-content: space-between;
  }

  &.column {
    flex-direction: column;
  }

  &.column-align {
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
  }
}

.pt-25 {
  padding-top: 25px;
}

.flex-order {
    & .flex-item {
      &:nth-of-type(1) {
        order: 1;
        @media screen and (max-width: 991px) {
          order: 2;
        }
      }

      &:nth-of-type(2) {
        order: 2;
        @media screen and (max-width: 991px) {
          order: 1;
        }
      }
    }
}

.form-control:focus {
  color: initial;
  background-color: initial;
  border-color: initial;
  outline: none;
  box-shadow: none;

}
.pb-0{
  padding-bottom: 0 !important;
}
.mb-4{
  margin-bottom: 4rem !important;
}

.tablet-none{
  @media screen and (max-width: 991px){
    display: none;
  }
}