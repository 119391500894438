

/* Select style here */

.select-custom {
  margin: 0 auto;
  color: #000;
  width: 245px;
  padding: 14px 20px 12px 12px;
  height: 38px;
  cursor: pointer;

  //background: url(http://sharpik.com/wip/cuteselect/arrow.png) 295px 12px no-repeat white;
}

/** Custom Select **/


.s-hidden {
  visibility:hidden;
  padding-right:10px;
}
.select {
  cursor:pointer;
  display:inline-block;
  position:relative;
  font-size: 13px;
  font-family: $gothamproregular;
  color: $color-grey;
  border:1px solid  $color-grey;
  border-radius: 10px;
  width: 256px;
  height: 45px;

}
.styledSelect {
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  background-color:#fff;
  font-size: 13px;
  font-family: $gothamproregular;
  color: $color-grey;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 6px;
  border-radius: 10px;
}
.styledSelect:after {
  content:"";
  width: 12px;
  height: 12px;
  position: absolute;
  top: 16px;
  right: 6px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgNDUxLjg0NyA0NTEuODQ3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NTEuODQ3IDQ1MS44NDc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48Zz4KCTxwYXRoIGQ9Ik0yMjUuOTIzLDM1NC43MDZjLTguMDk4LDAtMTYuMTk1LTMuMDkyLTIyLjM2OS05LjI2M0w5LjI3LDE1MS4xNTdjLTEyLjM1OS0xMi4zNTktMTIuMzU5LTMyLjM5NywwLTQ0Ljc1MSAgIGMxMi4zNTQtMTIuMzU0LDMyLjM4OC0xMi4zNTQsNDQuNzQ4LDBsMTcxLjkwNSwxNzEuOTE1bDE3MS45MDYtMTcxLjkwOWMxMi4zNTktMTIuMzU0LDMyLjM5MS0xMi4zNTQsNDQuNzQ0LDAgICBjMTIuMzY1LDEyLjM1NCwxMi4zNjUsMzIuMzkyLDAsNDQuNzUxTDI0OC4yOTIsMzQ1LjQ0OUMyNDIuMTE1LDM1MS42MjEsMjM0LjAxOCwzNTQuNzA2LDIyNS45MjMsMzU0LjcwNnoiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJhY3RpdmUtcGF0aCIgZGF0YS1vbGRfY29sb3I9IiMwMDAwMDAiIGZpbGw9IiM1NTU1NTUiLz4KPC9nPjwvZz4gPC9zdmc+Cg==');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.styledSelect:active, .styledSelect.active {
  background-color:#fff;
}
.options {
  display:none;
  position:absolute;
  top:35px;
  right:0;
  left:0;
  z-index:999;
  margin:0 0;
  padding:0 0;
  list-style:none;
  border:1px solid #fff;
  background-color:#fff;
  box-shadow: -4px -2px 20px -5px rgba(0,0,0,.26);
  height: 160px;
  overflow: scroll;
  overflow-x: hidden;
}
.options li {
  margin: 0;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 7px;
  font-size: 13px;
  font-family: $gothamproregular;
  color: $color-grey;
  text-align: left;
}
.options li:hover {
  color:#fff;
  background-color: $color-red;
}