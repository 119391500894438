.staff-item {
  max-width: 262px;
  @media screen and (max-width: 991px) {
    max-width: initial;
  }
  @media screen and (max-width: 568px) {
    max-width: 300px;
    margin: 0 auto;
  }

  & .staff-item-inner {
    & a {
      text-decoration: none;
    }

    & .shadow-box {
      //box-shadow: 0px 7px 20px -5px rgba(0, 0, 0, 0.26);
      //box-shadow: -7px 4px 20px -5px rgba(0, 0, 0, .26);
      //box-shadow: 3px 27px 54px -34px rgba(0, 0, 0, .47);
      //box-shadow: 0 3px 29px 0 rgba(0,0,0,.07);
      //box-shadow: 0 3px 14px 0 rgba(0,0,0,.05);
      box-shadow: 0 2px 7px 0 rgba(10, 10, 10, .05), 3px 27px 54px -34px rgba(0, 0, 0, .47);
      transition: .1s;
      @media screen and (max-width: 1024px) {
        overflow: hidden;
        margin-bottom: 37px;
      }
      @media screen and (max-width: 768px) {
        //box-shadow:0 2px 7px 2px rgba(10,10,10,.05);
        box-shadow: 0px 9px 20px -12px rgba(0, 0, 0, 0.31);
      }
      @media screen and (max-width: 568px) {
        //box-shadow:0 2px 7px 4px rgba(10,10,10,.05);
        box-shadow: 0px 9px 20px -12px rgba(0, 0, 0, 0.31);
      }
    }
  }

  &__image {
    //width: 262px;
    width: 100%;
    height: 253px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }

  &__description {
    background-color: #fff;
    padding: 8px 17px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 568px) {
    margin-bottom: 0;
  }

  &__name {
    font-size: 15px;
    text-align: center;
    color: $color-grey;
    font-family: $gothampromedium;
    margin-bottom: 13px;
  }

  &__info {
    font-size: 13px;
    text-align: center;
    color: $color-grey;
    font-family: $gothamproregular;
    margin-bottom: 0;
    line-height: 19px;
  }

  &__action {
    margin-bottom: 25px;

    &.btn {
      padding: 9px 14px;
      width: 100%;
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    & .staff-tag {
      padding: 7px 10px;
      opacity: 0.26;
      border-radius: 15px;
      background-color: $color-grey2;
      font-size: 13px;
      border: solid 1px $color-grey2;
      font-family: $gothampromedium;
      color: #fff;
      line-height: 16px;
      text-align: center;
      margin-right: 10px;
      margin-bottom: 10px;
      transition: .2s;

      &:hover {
        opacity: 0.8;
        border-radius: 15px;
        color: $color-grey2;
        border: solid 1px $color-grey2;
        background-color: #fff;
      }
    }
  }

  &:hover {
    & .shadow-box {
      //box-shadow: -4px -2px 20px -5px rgba(0, 0, 0, .26);
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .07);
    }
  }
}


.staff-card-review .reviews-carousel {
  margin-bottom: 50px;
}