.doctor-education {
  & svg {
    fill: $color-red;
    width: 100%;
    height: 30px;
    margin: 0 auto;
    margin-bottom: 9px;
  }

  & .title {
    margin-bottom: 37px;
    font-size: 16px;
  }

  & .education-stage-block {
    & ul{
      & li {
        position: relative;
        font-size: 15px;
        line-height: 22px;
        font-family: $gothamproregular;
        color: $color-grey;
        margin-left: 57px;
        margin-bottom: 23px;
        &::before {
          content: " ";
          position: absolute;
          left: -46px;
          top: 8px;
          width: 25px;
          height: 5px;
          background-color: $color-red;
        }
      }
    }
  }
}