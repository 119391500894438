//BELADENT
.form-group {
  & input {
    //padding: 15px 51px 12px 12px;
    padding: 15px 12px 12px 12px;
    color: $color-grey;
    font-size: 13px;
    font-family: $gothamproregular;
    border-radius: 10px;
    //height: 41px;
    height: 45px;
    width: 256px;
    border: 1px solid $color-grey;

    &::placeholder {
      color: $color-grey;
      font-size: 13px;
      font-family: $gothamproregular;
    }
  }
}

.form-group {
  & label {
    color: $color-grey;
    font-size: 15px;
    text-align: left;
    font-family: $gothamproregular;
  }
}

.select-group {
  //display: block;
  //position: absolute;
  max-width: 256px;
  height: 55px;
  width: 256px;
}

.form-error {
  color: $color-red;
  font-size: 10px;
  font-family: $gothampromedium;
  display: none;
}

.ui-timepicker-standard {
  width: 90px;
  z-index: 2 !important;
}

.ui-timepicker {
  width: 100px;

  & .ui-timepicker-viewport {
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-red;
      border-radius: 2px;
    }

    & .ui-corner-all {
      font-family: $gothamproregular;
      font-size: 14px;
      color: #555;
    }
  }

  & .ui-timepicker-standard {
    .ui-state-hover {
      background-color: $color-red;
      border: 1px solid $color-red;
    }
  }
}

.ui-timepicker-standard a {
  transition: none;

  &:hover {
    background-color: $color-red;
    border: 1px solid $color-red;
    color: #fff !important;
  }
}

//datepicker
.calendar {
  background: #FFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, .4);

  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
}

.date-input-fallback {
  display: none;
}

.js-no-type-date-support .date-input-native {
  display: none !important;
}

.js-no-type-date-support .date-input-fallback {
  display: inline-block !important;
}

@media (min-width: 1025px) {

  .date-input-fallback {
    display: inline-block;
  }
  .date-input-native {
    display: none;
  }
}

.form-row__custom {
  align-items: center;
  justify-content: space-between;
  max-width: 595px;
  @media screen and (max-width: 660px){
    flex-direction: column;
    & > div{
      flex-direction: column;
      align-items: flex-start;
    }
  }
  & .form-submit{
    padding-top: 11px;
    @media screen and (max-width: 660px){
      padding-top: 20px;
    }
  }
}

.check-doctor-form{
  & .select-group{
    height: auto;
  }
}