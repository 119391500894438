.custombt {
  & p {
    color: $color-grey;
    margin-bottom: 20px;
    text-align: justify;
    font-family: $gothamproregular;
    font-size: 15px;
    line-height: 24px;
    & strong,b{
      text-align: left;
      font-family: GothamProMedium;
      font-size: 27px;
      line-height: 24px;
      color: #555;
      margin-bottom: 43px;
      width: 100%;
      font-weight: initial;
      @media screen and (max-width: 991px) {
        font-size: 16px;
        line-height: 28px;
        text-align: left;
      }
    }
    & img{
      margin: 10px;
    }
  }

  & ul {
    max-width: 580px;
    margin: 0 auto;
    flex-direction: column;
    margin-bottom: 20px;

    & li {
      color: #555;
      position: relative;
      margin-left: 40px;
      margin-bottom: 7px;
      font-family: $gothamproregular;
      font-size: 15px;
      line-height: 24px;

      &::before {
        content: "";
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: #e31e24;
        position: absolute;
        left: -29px;
        top: 5px;
      }
    }
  }

  & .title_single {
    text-align: left;
    font-family: GothamProMedium;
    font-size: 27px;
    line-height: 24px;
    color: #555;
    margin-bottom: 43px;
    width: 100%;
    @media screen and (max-width: 991px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  & h1, h2, h3{
    text-align: left;
    font-family: GothamProMedium;
    font-size: 27px;
    line-height: 24px;
    color: #555;
    margin-bottom: 35px;
    width: 100%;
    @media screen and (max-width: 991px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
  & img{
    margin-top: 10px;
    margin-bottom: 30px;
    max-width: 865px;
    width: 100%;
  }
  & .carousel-owlc__wrapper{
    margin-bottom: 40px;
  }
}

//
.advantages_bg.advantages-list {
  & ul {
    flex-direction: column;

    & li {
      position: relative;
      margin-bottom: 33px;
      padding-left: 50px;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 21px;
      text-align: left;
      padding-bottom: 4px;
      font-family: $gothampromedium;
      @media screen and (max-width: 1200px) {
        font-size: 14px;
      }
      @media screen and (max-width: 991px) {
        font-size: 19px;
      }
      @media screen and (max-width: 568px) {
        font-size: 13px;
        margin-bottom: 15px;
      }

      &::before {
        position: absolute;
        top: 9px;
        left: 0;
        height: 5px;
        width: 25px;
        background-color: #e31e24;
        border-radius: 0;
      }
    }
  }

  min-height: 400px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;
}

.attention {
  background-image: url(../img/attention-bg.jpg);
  border-radius: 12px;
  border: 1px solid #bababa;
  padding: 16px 20px 23px;
  background-repeat: no-repeat;
  background-position: left;
  & h1, h2, h3 {
    font-family: GothamProMedium;
    color: #e31e24;
    margin-bottom: 10px;
    text-align: left;
    font-size: 15px;
    line-height: 24px;
  }

  & p {
    color: #555;
    margin-bottom: 0;
    text-align: left;
    padding: 0;
    font-family: GothamProRegular;
    font-size: 15px;
    line-height: 24px;
  }
}

//Подзраделение