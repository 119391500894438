.contact-map {
  padding-top: 84px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 30% -15px;
  margin-bottom: 129px;
  //@media screen and (max-width: 568px) {
  //  padding-left: 15px;
  //  padding-right: 15px;
  //  height: auto;
  //  padding-bottom: 40px;
  //  margin-bottom: 40px;
  //  background-position: center;
  //  background-size: contain;
  //}

  & .title {
    font-size: 36px;
    margin-bottom: 108px;
    @media screen and (max-width: 568px) {
      margin-bottom: 41px;
      font-size: 22px;
    }
  }

  &_single {
    //position: relative;
    @media screen and (max-width: 991px){
      margin-bottom: 77px;
    }
    @media screen and (max-width: 568px){
      padding-top: 25px;
    }
    & .title {
      //position: absolute;
      //margin-left: auto;
      //margin-right: auto;
      //left: 0;
      //right: 0;
      //top: 121px;
      @media screen and (max-width: 991px){
        position: initial;
      }
    }
    & .contact-map-wrapper {
      position: relative;
      box-shadow: none;
      & .single-map{
        padding-top: 19px;
        padding-bottom: 18px;
        width: 100%;
        @media screen and (max-width: 991px){
          position: initial;
          height: 480px;
          overflow: hidden;
        }
        @media screen and (max-width: 568px){
          position: initial;
          height: 100%;
        }
      }
      & .single-adress {
        box-shadow: 0 3px 6.4px 0.6px rgba(0, 0, 0, .2);
        max-width: 852px;
        width: 40%;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: #fff;
        padding-top: 100px;
        height: 100%;
        @media screen and (max-width: 1440px) {
          width: 50%;
        }
        @media screen and (max-width: 991px) {
          width: 100%;
          position: initial;
          max-width: inherit;
          padding-top: 0;
        }
        & .contact-bottom-wrapper{
          box-shadow: none;
          @media screen and (max-width: 991px){
            padding: 65px 0 1px 43px;
          }
          @media screen and (max-width: 768px){
            padding: 65px 0px 0px 0px;
          }
        }
      }
    }

  }
}


.contact-map-wrapper {
  @media screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & > div {
    &:first-of-type {
      width: 65%;
      @media screen and (max-width: 1440px) {
        width: 50%;
      }
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }

    &:last-of-type {
      width: 45%;
      z-index: 1;
      @media screen and (max-width: 1440px) {
        width: 50%;
      }
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }
}


.contact-bottom-wrapper {
  box-shadow: 0px 3px 6.4px 0.6px rgba(0, 0, 0, 0.2);
  padding: 42px 0 1px 96px;
  @media screen and (max-width: 1180px) {
    padding: 29px 0 1px 43px;
  }
  @media screen and (max-width: 991px) {
    box-shadow: 0px 3px 6.4px 0.6px rgba(0, 0, 0, 0.09);
  }
  @media screen and (max-width: 568px) {
    padding: 29px 5px 1px 5px;
  }
}

.contact-bottom {
  margin-bottom: 21px;
  @media screen and (max-width: 991px){
    margin-bottom: 50px !important;
  }
  & .block-title {
    & p{
      color: $color-red;
      font-family: $gothampromedium;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 46px;

      @media screen and (max-width: 991px) {
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }

  & .contact-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    @media screen and (max-width: 991px) {
      text-align: center;
      width: 100%;
    }

    &__title {
      & p{
        color: $color-grey;
        font-family: $gothampromedium;
        font-size: 18px;
        margin-bottom: 13px;
        @media screen and (max-width: 1180px) {
          font-size: 14px;
        }
        @media screen and (max-width: 568px) {
          text-align: center;
          margin-bottom: 5px;
        }
      }
    }

    &__content {
      color: #1d1d1d;
      font-size: 18px;
      font-family: $gothamproregular;
      max-width: 156px;
      line-height: 27px;
      margin-bottom: 31px;
      @media screen and (max-width: 1180px) {
        font-size: 14px;
      }
      @media screen and (max-width: 991px) {
        margin: 0 auto;
      }
      @media screen and (max-width: 568px) {
        text-align: center;
        max-width: initial;
        margin-bottom: 13px;
        display: flex;
        flex-direction: column;
      }
      & p{
        margin-bottom: 0;
      }
      & a {
        text-decoration: none;
        color: #1d1d1d;
        font-size: 18px;
        @media screen and (max-width: 1180px) {
          font-size: 14px;
        }
        @media screen and (max-width: 568px) {
          text-align: center;
        }

        &:hover {
          color: $color-red
        }
      }
    }

    .contact-item {
      margin-bottom: 17px;
    }
  }
}

@media screen and (max-width: 991px) {
  .contact-view {
    text-align: center;
    flex-direction: column;

    & .readmore__hide {
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 568px) {
  .contact-bottom {
    & .flex {
      flex-direction: column;
    }
  }
}


.contact-map {
  & .readmore {
    position: relative;
  }

  & .readmore__hide {
    position: relative;

    transition: all .5s ease-in-out;
    margin: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: 991px) {
      max-height: 0;
      overflow: hidden;
    }
  }

  & .readmore__hide:before {
    display: none;
    @media screen and (max-width: 991px) {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 4em;
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.7987570028011204) 0%, rgba(255, 255, 255, 0.01) 100%);
    }

  }

  & .readmore__hide.active {
    //max-height: 5000px;
    max-height: initial;
    overflow: inherit;
  }

  & .readmore__hide.active:before {
    display: none;
  }

  & .readmore__link {
    display: none;
    @media screen and (max-width: 991px) {
      display: block;
      margin: 0 auto;
      font-size: .875rem;
      padding: 9px 17px;
      max-width: max-content;
      transition: none;
      text-align: center;
      width: 100%;
      &:hover {
        cursor: pointer;
      }
    }
  }

  & .readmore__link span:last-of-type {
    display: none;
  }

  & .active + .readmore__link span:first-of-type {
    display: none;
  }

  & .active + .readmore__link span:last-of-type {
    display: flex;
    width: 145px;
    justify-content: center;
    align-items: center;

  }
}

.contact-view {
  &.active {
    & .readmore__link {

      display: block;
      margin: 0 auto;
      color: $color-red;
      font-size: .875rem;
      border: none;
      border-bottom: 1px solid $color-red !important;
      padding-bottom: 0;
      max-width: max-content;
      transition: none;
      text-align: center;
      width: 100%;
      border-radius: inherit;
      background: initial;

      &:hover {
        color: $color-red;
        cursor: pointer;
      }
    }
  }
}

.homepage{
  & .homepage-map{

  }
  & .homepage-contacts{
    box-shadow: 0 3px 6.4px 0.6px rgba(0,0,0,.2);
    padding: 42px 0 1px 96px;
    @media screen and (max-width: 991px){
      padding: 40px 0 30px 0px;
    }
    @media screen and (max-width: 768px){
      padding: 65px 0px 50px;
    }
    & .contact-bottom-wrapper{
      padding: 0;
      box-shadow: none;
      & .contact-bottom{
        margin-bottom: 0;
        @media screen and (max-width: 768px){
          margin-bottom: 21px;
        }
        & .block-title{
          line-height: 26px;
          margin-bottom: 28px;
        }

      }
    }
  }
}