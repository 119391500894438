.license-item {
  max-width: 275px;
  @media screen and (max-width: 991px) {
    max-width: initial;
    height: auto;
  }
  @media screen and (max-width: 660px){
    width: 275px;
    margin: 0 auto;
  }
  @media screen and (max-width: 568px) {
    max-width: initial;
    width: 100%;
    margin: 0 auto;
  }

  & a {
    & img {
      max-width: 275px;
      width: 100%;
      height: auto;
      //@media screen and (max-width: 991px){
      //  height: 100%;
      //  width: auto;
      //}
      @media screen and (max-width: 568px) {
        max-width: initial;
      }
    }
  }

}

.licenses-carousel {
  & .owl-nav {
    display: block !important;
  }

  & .owl-nav .owl-prev {
    position: absolute;
    left: -28px;
    top: 32px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 1366px) {

    }
    @media screen and (max-width: 1024px) {
      left: -16px;
    }
    @media screen and (max-width: 991px) {
      left: -15px;
      top: 50px;
    }
    @media screen and (max-width: 768px) {
      top: 50px;

    }
    @media screen and (max-width: 568px) {
      top: 50px;
    }
  }

  & .owl-nav .owl-next {
    position: absolute;
    right: -21px;
    top: 32px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 1366px) {

    }
    @media screen and (max-width: 1024px) {
      right: -16px;
    }
    @media screen and (max-width: 991px) {
      right: -19px;
      top: -20px;
    }
    @media screen and (max-width: 768px) {
      top: 50px;

    }
    @media screen and (max-width: 568px) {
      top: 50px;

    }
  }

  & .owl-prev.disabled,
  & .owl-next.disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  & .prev-slide {
    background: url(../img/icons/nav-prev.png) no-repeat scroll 0 0;
    background-size: contain;
    //left: -33px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 768px) {
      width: 10px;

    }
  }

  & .next-slide {
    background: url(../img/icons/nav-next.png) no-repeat scroll 0 0;
    background-size: contain;
    //right: -33px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 768px) {
      width: 10px;

    }
  }

  & .prev-slide:hover {
    //background-position: 0px -53px;
  }

  & .next-slide:hover {
    //background-position: -24px -53px;
  }
}