.sidebar-links {
  width: 300px;
  box-shadow: 0px 3px 9.3px 0.7px rgba(0, 0, 0, 0.13);
  padding: 27px 40px 35px 27px;
  margin-bottom: 50%;
  @media screen and (max-width: 1024px){
    max-width: 300px;
    width: 100%;
  }
  @media screen and (max-width: 991px) {
    max-width: 640px;
    margin: 0 auto;
    margin-bottom: 53px;
  }
  @media screen and (max-width: 640px){
    max-width: 100%;
    margin: 0 auto 60px 10px;
    width: auto;
    padding: 27px 18px 35px 27px;
  }
  & .link-item {
    margin-bottom: 17px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & span {
      & svg {
        fill: $color-grey;
        width: 20px;
        height: 20px;
        margin-right: 16px;
      }

      & a {
        color: $color-grey;
        font-family: $gothampromedium;
        font-size: 16px;

        &:hover {
          color: $color-red;
        }
      }
    }

    &:hover {
      & svg {
        fill: $color-red;
      }

      & a {
        color: $color-red;
      }
    }
  }
}