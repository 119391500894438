.breadcrumbs{
  //margin-bottom: 65px;
  margin-bottom: 0;
  padding-top: 175px;
  @media screen and (max-width: 1366px){
    & .container{
      max-width: 1040px;
    }
  }
  @media screen and (max-width: 991px){
    padding-top: 0;
  }
  @media screen and (max-width: 568px){
    margin-bottom: 30px;
  }
  &__items {
    font-family: $gothamproregular;
    font-size: 12px;
    color: $color-grey2;
    margin-bottom: 30px;
    //margin-top: -8px;
    //margin-top: 15px;

    margin-top: 31px;
    @media screen and (max-width: 1366px) {
      font-size: 12px;
      margin-left: 0;
    }
    @media screen and (max-width: 768px) {
      text-align: center;
      margin-bottom: 25px;
      font-size: 11px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 10px;
      margin-left: 0;
    }
    & span {
      color: $color-grey2;
    }
    & a {
      text-decoration: none;
      color: $color-grey2;
      transition: .2s;
      &:hover {
        color: $color-red;
      }
    }
    & .active {
      color: $color-red;
    }
  }
}
