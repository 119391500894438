//Модал Отзыва

.cd-popup_review {
  & .cd-popup-container {
    max-width: 876px;
    width: 100%;

    & .callback-form {
      background-color: initial;
      padding: 0;
      border-radius: 0;
      z-index: 100;

      & .online-leave-review {
        padding-top: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        position: relative;
        @media only screen and (max-width: 660px) {
          max-width: 315px;
          margin: 0 auto;
        }

        & .leave-review-form-wrapper {
          position: relative;
          @media only screen and (max-width: 1280px) {
            padding: 38px 25px 21px 30px;
          }
          @media only screen and (max-width: 660px) {
            padding: 38px 11px 21px 20px;
            & .form-group input {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 660px){
  .cd-popup_review .online-leave-review .leave-review-form-wrapper .leave-review-form .form-row .form-group{
    margin-right: 0px;
  }

  .cd-popup_review .online-leave-review .leave-review-form-wrapper .leave-review-form .form-row .form-group input{
    width: 100% !important;
  }
  .cd-popup_review .online-leave-review .leave-review-form-wrapper .leave-review-form .form-row .form-group.select-group{
    width: 100% !important;
    max-width: initial;
    margin-right: 0;
  }
  .cd-popup_review .online-leave-review .leave-review-form-wrapper .leave-review-form .form-row .form-group .select{
    width: 100%;
    margin-right: 0 !important;
  }
  .cd-popup_review .online-leave-review .leave-review-form-wrapper .leave-review-form .form-row .form-group .select-custom{
    width: 100% !important;
  }
  .cd-popup_review .online-leave-review .leave-review-form-wrapper  .leave-review-form .form-group.textfield textarea{
    width: 100%;
  }

}

@media only screen and (max-height: 823px){
  .cd-popup_review .online-leave-review .leave-review-form-wrapper{
    overflow-x: hidden;
    overflow-y: scroll;
    height: 563px;
    padding-bottom: 100px !important;
  }
}

@media only screen and (max-width: 360px){
  .cd-popup_review .online-leave-review .leave-review-form-wrapper .leave-review-form .form-row .form-group input{
    padding: 15px 2px 12px 12px !important;
  }
}

@media only screen and (max-width: 320px){
  .cd-popup_review .online-leave-review .leave-review-form-wrapper .leave-review-form .form-row .form-group input{
    padding: 15px 2px 12px 12px !important;
  }
}