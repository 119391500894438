.service-gallery {

}

.service-gallery-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 75%;
  margin: 0 auto;
  @media screen and (max-width: 768px){
    flex-direction: column;
    max-width: 100%;
  }
  &__inner {
    @media screen and (max-width: 768px){
      margin-bottom: 36px;
      width: 100%;
      padding-left: 20px;
    }
    & div {
      font-size: 15px;
      color: $color-grey;
      font-family: $gothamproregular;
      margin-bottom: 10px;
      & span {
        font-family: $gothampromedium;
      }

    }
  }
}


//CAROUSEL
.service-gallery-item {
  max-width: 375px;
  @media screen and (max-width: 991px) {
    max-width: initial;
    height: 200px;
  }
  @media screen and (max-width: 568px){
    height: auto;
  }
  @media screen and (max-width: 568px) {
    max-width: initial;
    width: 100%;
    margin: 0 auto;
  }

  & a{
    & img{
      width: 100%;
      height: auto;
      //@media screen and (max-width: 991px){
      //  height: 100%;
      //  width: auto;
      //}
      @media screen and (max-width: 568px){
        max-width: initial;
      }
    }
  }

}

.gallery-carousel {
  & .owl-nav {
    display: block !important;
  }

  & .owl-nav .owl-prev {
    position: absolute;
    left: -28px;
    top: -61px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 1024px) {
      left: -16px;
    }
    @media screen and (max-width: 991px) {
      left: -15px;
      top: -20px;
    }
    @media screen and (max-width: 768px){
      top: -75px;
    }
    @media screen and (max-width: 568px){
      top: -61px;
    }
    //@media screen and (max-width: 568px){
    //
    //}
  }

  & .owl-nav .owl-next {
    position: absolute;
    right: -21px;
    top: -61px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 1024px) {
      right: -16px;
    }
    @media screen and (max-width: 991px) {
      right: -19px;
      top: -20px;
    }
    @media screen and (max-width: 768px){
      top: -75px;
    }
    @media screen and (max-width: 568px){
      top: -61px;
    }
  }

  & .owl-prev.disabled,
  & .owl-next.disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  & .prev-slide {
    background: url(../img/icons/nav-prev.png) no-repeat scroll 0 0;
    background-size: contain;
    //left: -33px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 768px){
      width: 10px;

    }
  }

  & .next-slide {
    background: url(../img/icons/nav-next.png) no-repeat scroll 0 0;
    background-size: contain;
    //right: -33px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 768px){
      width: 10px;

    }
  }

  & .prev-slide:hover {
    //background-position: 0px -53px;
  }

  & .next-slide:hover {
    //background-position: -24px -53px;
  }
}

.carousel-owlc__wrapper.service-gallery .owl-nav .owl-prev{
  top: -37px;
}
.carousel-owlc__wrapper.service-gallery .owl-nav .owl-next{
  top: -37px;
}

.service-gallery .carousel-owlc{
  margin-bottom: 0;
}
.service-gallery .carousel-owlc__wrapper{
  margin-bottom: 31px;
}

//SERVICE STAFF CAROUSEL
.owl-carousel.carousel-owlc__wrapper.service-staff{
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 568px){
  .carousel-owlc__wrapper.service-gallery .owl-nav .owl-prev{
    top: -61px;
  }
  .carousel-owlc__wrapper.service-gallery .owl-nav .owl-next{
    top: -61px;
  }
}


.simple-service-gallery{
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
  & .service-gallery-item{
    margin-bottom: 20px;
    max-width:375px;
  }
  @media screen and (max-width: 568px){
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    & .service-gallery-item{
      margin-bottom: 20px;
    }
  }
}