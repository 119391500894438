.news-item {
  max-width: 262px;
  @media screen and (max-width: 991px){
    max-width: initial;
  }
  @media screen and (max-width: 568px) {
    max-width: 100%;
    margin: 0 auto;
    width: auto;
  }

  & .news-item-inner {
    & a {
      margin-right: 20px;
      text-decoration: none;
    }
  }

  &__image {
    width: 262px;
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: 10px;
    @media screen and (max-width: 991px){
      width: 100%;
    }
  }

  &__description {
    background-color: #fff;
    padding: 8px 0px;
    margin-bottom: 10px;
  }
  &__title {
    font-size: 18px;
    text-align: left;
    color: $color-grey;
    font-family: $gothampromedium;
    margin-bottom: 12px;
  }
  &__text {
    font-size: 13px;
    text-align: left;
    color: $color-grey;
    font-family: $gothamproregular;
    height: 65px;
    line-height: 16px;
    //text-overflow: ellipsis;
    //overflow: hidden;
    //width: 160px;
    //white-space: nowrap;
    margin-bottom: 9px;
  }
  &__date{
    margin-bottom: 3px;
    font-size: 10px;
    color: $color-red;
    font-family: $gothampromedium;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    & .news-tag {
      padding: 4px 0px;
      font-size: 11px;
      font-family: $gothampromedium;
      color: $color-grey2;
      border-bottom: 1px solid $color-grey;
      line-height: 16px;
      text-align: center;
      margin-right: 10px;
      margin-bottom: 10px;
      &:hover {
        color: $color-red;
        border-bottom: 1px solid $color-red;
      }
    }
  }
}
