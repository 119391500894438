

@import "doctor-avatar.scss";
@import "doctor-education.scss";
@import "doctor-sertificate.scss";


@media screen and (max-width: 991px){
  .row-reverse{
    flex-direction: column-reverse;
  }
}


.staff-card-review{
& .all-owlc_reviews .btn:last-of-type{
  display: block;
}
}

.all-staff-more{
  display: none;
  text-align: center;
  margin-bottom: 40px;
  @media screen and (max-width: 991px){
    display: block;
  }
  & .btn{
    padding: 10px 15px;
  }
}