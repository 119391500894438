.post-gallery{
  & .gallery-item{
    width: 275px;
    & img{
      max-width: 275px;
      width: 100%;
    }
  }
  &.carousel-owlc__wrapper{
    & .owl-nav{
      top: 85px;
      @media screen and (max-width: 568px){
        top: 27px;
      }
    }
  }
}