.privilege {
  padding-top: 84px;
  background-color: #fff;
  background-image: url("../img/privilege-bg.png");
  background-repeat: no-repeat;
  background-position: 30% -15px;
  height: 810px;
  @media screen and (max-width: 991px){
    background-position: -110% -47px;
  }
  @media screen and (max-width: 768px){
    background-position: -239px -45px;
  }
  @media screen and (max-width: 568px){
    padding-left: 15px;
    padding-right: 15px;
    height: auto;
    padding-bottom: 55px;
    margin-bottom: 40px;
    background-position: -167px -46px;
    background-size: 500px 500px;
  }
  @media screen and (max-width: 375px){
    background-position: -223px -71px;
    background-size: 600px 600px;
  }
  @media screen and (max-width: 320px){
    background-position: -221px -40px;
    background-size: 550px 550px;
  }
  & .title{
    font-size: 36px;
    margin-bottom: 67px;
    @media screen and (max-width: 568px){
      margin-bottom: 41px;
      font-size: 22px;
    }
  }
}
.privilege-list{
  & ul{
    & li{
      margin-bottom: 25px;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 21px;
      text-align: justify;
      color: $color-grey;
      font-family: $gothampromedium;
      padding-bottom: 4px;
      @media screen and (max-width: 568px){
        font-size: 13px;
        margin-bottom: 15px;
        text-align: left;
      }
      //& a{
      //  text-decoration: none;
      //  text-transform: uppercase;
      //  font-size: 21px;
      //  text-align: justify;
      //  color: $color-grey;
      //  font-family: $gothampromedium;
      //  padding-bottom: 4px;
      //  @media screen and (max-width: 568px){
      //    font-size: 13px;
      //  }
      //}
      //&:hover{
      //  & a{
      //    color: $color-red;
      //    border-bottom: 1px solid $color-red;
      //  }
      //}
    }
  }
}