.carousel-owlc__wrapper {
  position: relative;
  margin-bottom: 68px;
  @media screen and (max-width: 568px) {
    margin-bottom: 49px;
  }

  & .owl-nav {
    display: block;
    position: absolute;
    top: 130px;
    width: 100%;
    cursor: pointer;
  }

  &.news {
    & .owl-nav {
      top: 93px;
    }
  }
}

.carousel-owlc {
  margin-bottom: 150px;
  padding-left: 4px;
  padding-right: 4px;
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }

}

.all-owlc {
  text-align: center;

  & a {
    margin: 0 auto;
  }

  &_reviews {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 26px;
    @media screen and (max-width: 568px) {
      flex-direction: column;
      align-items: center;
      width: 230px;
      margin: 0 auto;
    }

    & .btn {
      margin: inherit;
      margin-right: 30px;

      &:last-of-type {
        display: none;
      }

      @media screen and (max-width: 568px) {
        width: 100%;
        font-size: 14px;
        margin-right: 0;
        margin-bottom: 30px;
        &:last-of-type {
          display: block;
        }
      }
    }
  }

  &_question {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 26px;
    @media screen and (max-width: 568px) {
      flex-direction: column;
      align-items: center;
      width: 230px;
      margin: 0 auto;
    }

    & .btn {
      margin: inherit;
      margin-right: 30px;

      &:last-of-type {
        display: block;
      }

      @media screen and (max-width: 568px) {
        width: 100%;
        font-size: 14px;
        margin-right: 0;
        margin-bottom: 30px;
        &:last-of-type {
          display: block;
        }
      }
    }

    & .select {
      height: 45px;
    }
  }
}

//.carousel-owlc__wrapper.owl-nav {
//  height: 47px;
//  position: absolute;
//  width: 26px;
//
//  top: 100px !important;
//}
.carousel-owlc__wrapper .owl-nav {
  display: block !important;
}

.carousel-owlc__wrapper .owl-nav .owl-prev {
  position: absolute;
  left: -28px;
  height: 30px;
  width: 16px;
  @media screen and (max-width: 1024px) {
    left: -16px;
  }
  @media screen and (max-width: 991px) {
    left: -21px;
  }
  @media screen and (max-width: 568px) {
    top: 56px;
  }
}

.carousel-owlc__wrapper .owl-nav .owl-next {
  position: absolute;
  right: -26px;
  height: 30px;
  width: 16px;
  @media screen and (max-width: 1024px) {
    right: -16px;
  }
  @media screen and (max-width: 991px) {
    right: -21px;
  }
  @media screen and (max-width: 568px) {
    top: 56px;
  }
}


.carousel-owlc__wrapper .owl-prev.disabled,
.carousel-owlc__wrapper .owl-next.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.carousel-owlc__wrapper .prev-slide {
  background: url(../img/icons/nav-prev.png) no-repeat scroll 0 0;
  //left: -33px;
  height: 30px;
  width: 16px;
}

.carousel-owlc__wrapper .next-slide {
  background: url(../img/icons/nav-next.png) no-repeat scroll 0 0;
  //right: -33px;
  height: 30px;
  width: 16px;
}

.carousel-owlc__wrapper .prev-slide:hover {
  //background-position: 0px -53px;
}

.carousel-owlc__wrapper .next-slide:hover {
  //background-position: -24px -53px;
}

@import "staff-item.scss";
@import "news-item.scss";
@import "review-item.scss";
//@import "main-review-item.scss";
@import "gallery-item.scss";
@import "video-item.scss";
@import "license-item.scss";
@import "question-item.scss";

.reviews-carousel {
  background-color: #ededed;
  padding-top: 38px;
  margin-bottom: 240px;
  @media screen and (max-width: 568px) {
    margin-bottom: 60px;
  }

  & .carousel-owlc {
    margin-bottom: 38px;

    & .carousel-owlc__wrapper {
      margin-bottom: 0;
    }
  }
}

.questions-carousel {
  background-color: #f4f4f4;
  padding-top: 40px;
}

//ДОБАВИТЬ КОММЕНТАРИЙ

.add-comment {
  &:hover {
    cursor: pointer;
    color: $color-red;
    border-bottom: 1px solid $color-red;
  }
}

.add-comment__block {
  display: none;
  padding-bottom: 20px;

  & form {
    & .form-row {
      &.w100 {
        width: 100%;
      }

      & .form-group {
        & input {
          border: none;
          border-bottom: 1px solid #555;
          border-radius: 0;
          padding-left: 0;
        }

        &.textfield {
          width: 100%;

          & textarea {
            font-size: 15px;
            color: $color-grey2;
            font-family: $gothamproregular;
            border: 1px solid $color-grey;
            padding: 15px 10px 7px 15px;
            width: 100%;
            border-radius: 12px;

            &::placeholder {
              font-size: 15px;
              color: #bababa;
              font-family: $gothamproregular;
            }
          }
        }
      }
    }
  }
}

.question-item-inner {
  &.add {
    & .add-comment__block {
      display: block;
    }
  }
}

.review-item-inner {
  &.add {
    & .add-comment__block {
      display: block;
    }
  }
}

//END ДОБАВИТЬ КОММЕНТАРИЙ


.owl-carousel.carousel-owlc__wrapper.question .owl-nav .owl-prev {
  top: 72px;
}

.owl-carousel.carousel-owlc__wrapper.question .owl-nav .owl-next {
  top: 72px;
}


.review.slider .owl-nav .owl-prev {
  top: 65px;
  @media screen and (max-width: 568px) {
    top: 80px;
  }
}

.review.slider .owl-nav .owl-next {
  top: 65px;
  @media screen and (max-width: 568px) {
    top: 80px;
  }
}

//.questions-carousel .question-item__content .readmore-comment{
//  margin-top: 0;
//}

.questions-carousel {
  & .doctor-wrapper {
    flex-direction: column;

    & img {
      margin-bottom: 20px;
    }
  }
}

.staff-card-review {
  & .btn {
    max-width: 150px;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 10px 10px;
  }
}

.review-about-staff {
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: 568px) {
    flex-direction: column;
  }

  & .btn {
    margin: 0 !important;
    margin-right: 30px !important;
    max-width: initial;
    @media screen and (max-width: 568px) {
      margin-right: 0 !important;
      margin-bottom: 30px !important;
    }
  }
}

.all-owlc.all-owlc_reviews {
  & .btn {
    &:last-of-type {
      display: none;
    }
  }
}

.carousel-owlc__wrapper.news .owl-nav .owl-prev {
  @media screen and (max-width: 568px) {
    top: -9px;
  }
}

.carousel-owlc__wrapper.news .owl-nav .owl-next {
  @media screen and (max-width: 568px) {
    top: -9px;
  }
}


.questions-carousel {
  & .carousel-owlc {
    margin-bottom: 0;

    & .carousel-owlc__wrapper {
      margin-bottom: 46px;
    }
  }
}

.licenses-carousel {
  @media screen and (max-width: 568px) {
    margin-bottom: 115px;
  }
}

.carousel-owlc__wrapper.review .owl-nav .owl-prev {

  top: 72px;

}

.carousel-owlc__wrapper.review .owl-nav .owl-next {
  top: 72px;

}


.carousel-owlc__wrapper.review .owl-nav .owl-prev {
  @media screen and (max-width: 568px) {
    top: 92px;
  }
}
.carousel-owlc__wrapper.review .owl-nav .owl-next {
  @media screen and (max-width: 568px) {
    top: 92px;
  }
}


.owl-carousel.carousel-owlc__wrapper.video-carousel{
  position: relative;
  & .owl-nav{
    top: 50%;
    & .owl-prev{
      top: 50%;
    }
    & .owl-next{
      top: 50%;
    }
  }
}

@media screen and (max-width: 568px) {
  .single-post-wrapper .carousel-owlc__wrapper .owl-nav .owl-prev{
    left: -11px;
  }
  .single-post-wrapper .carousel-owlc__wrapper .owl-nav .owl-next{
    right: -11px;
  }
}