.pulse-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 80px;
  @media screen and (max-width: 635px){
    flex-direction: column-reverse;
  }
}
//
.cbh-ph-circle,
.cbh-subvision {
  background-color: transparent
}
.cbh-subvision {
  visibility: hidden;
  width: 200px;
  height: 200px;
  cursor: pointer;
  //z-index: 200000!important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility .5s;
  -moz-transition: visibility .5s;
  -o-transition: visibility .5s;
  transition: visibility .5s
}
.cbh-subvision.cbh-show {
  visibility: visible
}
.cbh-subvision.cbh-static {
  opacity: .8
}
.cbh-subvision:hover {
  opacity: 1
}
.cbh-ph-circle {
  //top: 20px;
  //left: 20px;
  width: 160px;
  height: 160px;
  top: 21px;
  left: 23px;
  position: absolute;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid rgba(30, 30, 30, .4);
  opacity: .1;
  -webkit-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -moz-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -ms-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -o-animation: cbh-circle-anim 1.2s infinite ease-in-out;
  animation: cbh-circle-anim 1.2s infinite ease-in-out;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}
.cbh-subvision.cbh-active .cbh-ph-circle {
  -webkit-animation: cbh-circle-anim 1.1s infinite ease-in-out!important;
  -moz-animation: cbh-circle-anim 1.1s infinite ease-in-out!important;
  -ms-animation: cbh-circle-anim 1.1s infinite ease-in-out!important;
  -o-animation: cbh-circle-anim 1.1s infinite ease-in-out!important;
  animation: cbh-circle-anim 1.1s infinite ease-in-out!important
}
.cbh-subvision.cbh-static .cbh-ph-circle {
  -webkit-animation: cbh-circle-anim 2.2s infinite ease-in-out!important;
  -moz-animation: cbh-circle-anim 2.2s infinite ease-in-out!important;
  -ms-animation: cbh-circle-anim 2.2s infinite ease-in-out!important;
  -o-animation: cbh-circle-anim 2.2s infinite ease-in-out!important;
  animation: cbh-circle-anim 2.2s infinite ease-in-out!important
}
.cbh-subvision.cbh-hover .cbh-ph-circle {
  border-color: #00aff2;
  opacity: .5
}
//.cbh-subvision.cbh-pulse.cbh-hover .cbh-ph-circle {
//  border-color: #75eb50;
//  opacity: .5
//}
.cbh-subvision.cbh-pulse .cbh-ph-circle {
  border-color: $color-red;
  opacity: 1
}
//.cbh-subvision.cbh-gray .cbh-ph-circle,
//.cbh-subvision:hover .cbh-ph-circle {
//  border-color: #75eb50;
//  opacity: .5
//}
.cbh-ph-circle-fill {
  width: 160px;
  height: 160px;
  top: 20px;
  left: 22px;
  position: absolute;
  background-color: #000;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: .1;
  -webkit-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  animation: cbh-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all .5s;
  -moz-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}
.cbh-subvision.cbh-active .cbh-ph-circle-fill {
  -webkit-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out!important;
  -moz-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out!important;
  -ms-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out!important;
  -o-animation: cbh-circle-fill-anim 1.7s infinite ease-in-out!important;
  animation: cbh-circle-fill-anim 1.7s infinite ease-in-out!important
}
.cbh-subvision.cbh-static .cbh-ph-circle-fill {
  -webkit-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out!important;
  -moz-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out!important;
  -ms-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out!important;
  -o-animation: cbh-circle-fill-anim 2.3s infinite ease-in-out!important;
  animation: cbh-circle-fill-anim 2.3s infinite ease-in-out!important;
  opacity: 0!important
}
.cbh-subvision.cbh-pulse .cbh-ph-circle-fill {
  background-color: rgba(227,30,36, 0.5);
  opacity: 1!important
}
//.cbh-subvision.cbh-pulse.cbh-hover .cbh-ph-circle-fill,
//.cbh-subvision:hover .cbh-ph-circle-fill {
//  background-color: rgba(117, 235, 80, .5);
//  opacity: .75!important
//}
//.cbh-subvision.cbh-gray.cbh-hover .cbh-ph-circle-fill {
//  background-color: rgba(204, 204, 204, .5);
//  opacity: .75!important
//}
//.cbh-subvision.cbh-gray .cbh-ph-circle-fill {
//  background-color: rgba(117, 235, 80, .5);
//  opacity: .75!important
//}
.cbh-ph-img-circle {
  width: 80px;
  height: 80px;
  top: 61px;
  left: 63px;
  position: absolute;
  //background-color: rgba(30, 30, 30, .1);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  //border: 2px solid transparent;
  opacity: 1;
  -webkit-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -o-animation: cbh-circle-img-anim 1s infinite ease-in-out;
  animation: cbh-circle-img-anim 1s infinite ease-in-out;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%
}
.cbh-subvision.cbh-active .cbh-ph-img-circle {
  -webkit-animation: cbh-circle-img-anim 1s infinite ease-in-out!important;
  -moz-animation: cbh-circle-img-anim 1s infinite ease-in-out!important;
  -ms-animation: cbh-circle-img-anim 1s infinite ease-in-out!important;
  -o-animation: cbh-circle-img-anim 1s infinite ease-in-out!important;
  animation: cbh-circle-img-anim 1s infinite ease-in-out!important
}
.cbh-subvision.cbh-static .cbh-ph-img-circle {
  -webkit-animation: cbh-circle-img-anim 0s infinite ease-in-out!important;
  -moz-animation: cbh-circle-img-anim 0s infinite ease-in-out!important;
  -ms-animation: cbh-circle-img-anim 0s infinite ease-in-out!important;
  -o-animation: cbh-circle-img-anim 0s infinite ease-in-out!important;
  animation: cbh-circle-img-anim 0s infinite ease-in-out!important
}
//.cbh-subvision:hover .cbh-ph-img-circle {
//  background-color: #75eb50!important;
//  opacity: 1
//}
//.cbh-subvision.cbh-pulse.cbh-hover .cbh-ph-img-circle {
//  background-color: #75eb50
//}
.cbh-subvision.cbh-pulse .cbh-ph-img-circle {
  background-position: center;
  background-size: initial;
  background-repeat: no-repeat;
}
.cbh-subvision.cbh-gray.cbh-hover .cbh-ph-img-circle {
  background-color: #ccc
}
//.cbh-subvision.cbh-gray .cbh-ph-img-circle {
//  background-color: #75eb50
//}
.error-field {
  border: 1px solid red!important
}
@-moz-keyframes cbh-circle-anim {
  0% {
    -moz-transform: rotate(0) scale(.5) skew(1deg);
    opacity: .1;
    -moz-opacity: .1;
    -webkit-opacity: .1;
    -o-opacity: .1
  }
  30% {
    -moz-transform: rotate(0) scale(.7) skew(1deg);
    opacity: .5;
    -moz-opacity: .5;
    -webkit-opacity: .5;
    -o-opacity: .5
  }
  100% {
    -moz-transform: rotate(0) scale(1) skew(1deg);
    opacity: .6;
    -moz-opacity: .6;
    -webkit-opacity: .6;
    -o-opacity: .1
  }
}
@-webkit-keyframes cbh-circle-anim {
  0% {
    -webkit-transform: rotate(0) scale(.5) skew(1deg);
    -webkit-opacity: .1
  }
  30% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    -webkit-opacity: .5
  }
  100% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    -webkit-opacity: .1
  }
}
@-o-keyframes cbh-circle-anim {
  0% {
    -o-transform: rotate(0) kscale(.5) skew(1deg);
    -o-opacity: .1
  }
  30% {
    -o-transform: rotate(0) scale(.7) skew(1deg);
    -o-opacity: .5
  }
  100% {
    -o-transform: rotate(0) scale(1) skew(1deg);
    -o-opacity: .1
  }
}
@keyframes cbh-circle-anim {
  0% {
    transform: rotate(0) scale(.5) skew(1deg);
    opacity: .1
  }
  30% {
    transform: rotate(0) scale(.7) skew(1deg);
    opacity: .5
  }
  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: .1
  }
}
@-moz-keyframes cbh-circle-fill-anim {
  0%, 100% {
    -moz-transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }
  50% {
    -moz-transform: rotate(0) -moz-scale(1) skew(1deg);
    opacity: .2
  }
}
@-webkit-keyframes cbh-circle-fill-anim {
  0%, 100% {
    -webkit-transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }
  50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg);
    opacity: .2
  }
}
@-o-keyframes cbh-circle-fill-anim {
  0%, 100% {
    -o-transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }
  50% {
    -o-transform: rotate(0) scale(1) skew(1deg);
    opacity: .2
  }
}
@keyframes cbh-circle-fill-anim {
  0%, 100% {
    transform: rotate(0) scale(.7) skew(1deg);
    opacity: .2
  }
  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: .2
  }
}
@keyframes cbh-circle-img-anim {
  0%, 100%, 50% {
    transform: rotate(0) scale(1) skew(1deg)
  }
  10%,
  30% {
    transform: rotate(-25deg) scale(1) skew(1deg)
  }
  20%,
  40% {
    transform: rotate(25deg) scale(1) skew(1deg)
  }
}
@-moz-keyframes cbh-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg)
  }
  10%,
  30% {
    -moz-transform: rotate(-25deg) scale(1) skew(1deg)
  }
  20%,
  40% {
    -moz-transform: rotate(25deg) scale(1) skew(1deg)
  }
  100%,
  50% {
    -moz-transform: rotate(0) scale(1) skew(1deg)
  }
}
@-webkit-keyframes cbh-circle-img-anim {
  0%, 100%, 50% {
    -webkit-transform: rotate(0) scale(1) skew(1deg)
  }
  10%,
  30% {
    -webkit-transform: rotate(-25deg) scale(1) skew(1deg)
  }
  20%,
  40% {
    -webkit-transform: rotate(25deg) scale(1) skew(1deg)
  }
}
@-o-keyframes cbh-circle-img-anim {
  0%, 100%, 50% {
    -o-transform: rotate(0) scale(1) skew(1deg)
  }
  10%,
  30% {
    -o-transform: rotate(-25deg) scale(1) skew(1deg)
  }
  20%,
  40% {
    -o-transform: rotate(25deg) scale(1) skew(1deg)
  }
}