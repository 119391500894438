.pagination-wrapper{
  display: flex;
  justify-content: center;
}
.pagination-pages {
  padding-left: 0;
  margin: 0 auto;
  margin-bottom: 70px;
  display: flex;
  flex-direction: row;

  &__prev {
    &.active {
      & svg {
        fill: $color-grey;
      }
    }

    & svg {
      transition: .3s;
      width: 12px;
      height: 20px;
      fill: $color-grey;
      transform: rotate(-180deg);

      & .active {
        fill: $color-grey;
      }

      &:hover {
        cursor: pointer;
        fill: $color-red;
      }
    }
  }

  &__next {
    &.active {
      & svg {
        fill: $color-grey;
      }
    }

    & svg {
      transition: .3s;
      width: 12px;
      height: 20px;
      fill: $color-grey;

      &:hover {
        cursor: pointer;
        fill: $color-red;
      }
    }
  }
}

.pages-numbers {
  display: flex;
  margin-left: 5px;
  margin-right: 5px;

  & a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    font-family: $gothamproregular;
    color: #bababa;
    transition: .3s;
    text-decoration: none;
    margin-left: 3px;
    margin-right: 3px;
    &.active {
      border: 1px solid $color-grey;
      color: $color-grey;
      border-radius: 50%;

      &:hover {
        border: 1px solid $color-grey;
        color: $color-grey;
        border-radius: 50%;
      }
    }

    &:hover {
      border: none;
      color: $color-red;
      border-radius: 0;
    }
  }

  &__more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 20px;
    font-family: $gothamproregular;
    color: $color-grey;
  }
}