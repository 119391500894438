.question-section {
  //background-color: #f4f4f4;
  padding-right: 60px;

  @media screen and (max-width: 991px) {
    padding: 37px 20px;
  }
  @media screen and (max-width: 568px) {
    padding: 37px 0px;
    margin-bottom: 20px;
  }

  & .question-item {
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 64px;
    @media screen and (max-width: 568px) {
      max-width: 100%;
    }

    & .question-item-heading {
      max-width: 314px;
    }

    &__tags {
      max-width: 400px;
      margin-bottom: 27px;
    }

    &__answer {
      & .title {
        font-size: 15px;
        text-align: left;
        margin-bottom: 10px;
      }

      & img {
        max-width: 175px !important;
        height: auto !important;
      }

      & .doctor-name {
        text-align: center;
      }

      & .doctor-position {
        text-align: center;
      }
    }

    .add-comment__block {
      & .form-row {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

.question-section-container{
  background-color: #f4f4f4;
  padding: 37px 70px 0px;
  margin-bottom: 45px;
  @media screen and (max-width: 991px){
    padding: 0;
  }
}