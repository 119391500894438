.intro-about {
  margin-bottom: 131px;
  //padding-top: 155px;
  @media screen and (max-width: 991px){
    margin-bottom: 30px;
    //padding-top: 0;
  }
  & .intro-about-wrapper {
    max-height: 420px;
    max-width: 1200px;
    margin: 0 auto;
    //overflow: hidden;
    & .intro-image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 420px;
      max-width: 1200px;
      @media screen and (max-width: 991px) {
        height: 275px;
      }
    }
  }

}
