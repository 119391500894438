.sidebar-subscribe {
  background-image: url(../img/subscribe-bg.png);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto;
  padding: 25px 18px;
  width: 100%;
  box-shadow: 0 3px 9.3px 0.7px rgba(0, 0, 0, .13);

  & .title {
    color: $color-red;
    font-size: 20px;
    font-family: $gothampromedium;
  }

  &__wrapper {
    & form {
      & .form-row {
        width: 100%;

        & .form-group {
          width: 100%;

          & label {
            font-size: 16px;
          }

          & input {
            width: 100%;
            padding: 10px;
          }
        }
      }
    }
  }
}


.sidebar-subscribe__wrapper .form-row.flex.justify-content-lg-center {
  @media screen and (max-width: 568px) {
    justify-content: center;
  }
}


.single-post-wrapper .mobile-subscribe .sidebar-subscribe {
  display: none;
}

@media screen and (max-width: 991px) {
  .single-post-wrapper .sidebar-subscribe {
    display: none;
  }
  .single-post-wrapper .mobile-subscribe .sidebar-subscribe {
    margin-bottom: 30px;
    display: block;
  }
}

