.staff-sertificate {
  margin-top: 53px;
  margin-bottom: 60px;
  @media screen and (max-width: 640px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  & .carousel-owlc__wrapper .owl-nav .owl-prev {
    @media screen and (max-width: 568px) {
      top: 25px;
    }
    @media screen and (max-width: 375px) {
      top: 32px;
    }
  }

  & .carousel-owlc__wrapper .owl-nav .owl-next {
    @media screen and (max-width: 568px) {
      top: 25px;
    }
    @media screen and (max-width: 375px) {
      top: 32px;
    }
  }
}

