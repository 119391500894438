.price-page{
  padding-top: 155px;
  @media screen and (max-width: 991px){
    padding-top: 0;
  }
  & .breadcrumbs{
    padding-top: 0;
  }
}
.price-accordion {
  margin-bottom: 70px;

  @media screen and (max-width: 991px) {
    max-width: 640px;
    margin: 0 auto;
    margin-bottom: 70px;
  }

  & .title {
    font-size: 27px;
    margin-bottom: 52px;
  }

  &__inner {
    & .accordion-item {
      margin-bottom: 14px;

      & .price-accordion-toggle-title {
        background-color: $color-grey;
        opacity: 0.25;
        color: #fff;
        position: relative;
        width: 100%;
        display: block;
        font-family: $gothampromedium;
        font-size: 24px;
        transition: background .3s ease;
        //margin-bottom: 13px;
        padding: 18px 5px 18px 29px;
        border-radius: 12px;

        & p {
          margin-bottom: 0;
          color: #fff;
          @media screen and (max-width: 768px) {
            width: 90%;
            display: block;
          }
        }

        &:hover {
          opacity: 1;
          background-color: $color-red;
          cursor: pointer;
          font-family: $gothampromedium;

          &::after {
            background-color: #d87f82;
          }
        }

        &.active {
          opacity: 1;
          background-color: $color-red;
          color: #fff;

          &::after {
            transform: rotate(0deg);
            background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDQyIDQyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0MiA0MjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGRkZGRjt9DQo8L3N0eWxlPg0KPHJlY3QgeT0iMjAiIGNsYXNzPSJzdDAiIHdpZHRoPSI0MiIgaGVpZ2h0PSIyIi8+DQo8L3N2Zz4NCg==');
            background-color: #d87f82;
          }
        }

        &::after {
          content: "";
          position: absolute;
          right: 11px;
          top: 11px;
          width: 39px;
          height: 39px;
          border-radius: 50%;
          background-color: #ababab;
          background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDMxLjEgMzEuMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzEuMSAzMS4xOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPg0KCS5zdDB7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8cG9seWdvbiBjbGFzcz0ic3QwIiBwb2ludHM9IjMxLjEsMS40IDI5LjcsMCAxNS42LDE0LjEgMS40LDAgMCwxLjQgMTQuMSwxNS42IDAsMjkuNyAxLjQsMzEuMSAxNS42LDE3IDI5LjcsMzEuMSAzMS4xLDI5LjcgDQoJMTcsMTUuNiAiLz4NCjwvc3ZnPg0K');
          background-repeat: no-repeat;
          transition: .3s;
          transform: rotate(45deg);
          padding: 18px;
          background-size: 20px;
          background-position: center;
          @media screen and (max-width: 768px) {
            top: 28%;
            width: 20px;
            height: 20px;
            background-size: 16px;
            padding: 14px;
            right: 4px;
            //top: 13px;
          }
        }

        @media screen and (max-width: 768px) {
          font-size: 18px;
          padding-left: 5px;
        }
      }

      & .accordion-inner {
        //transition: .3s;
        display: none;
        margin-left: 0;
        padding-bottom: 5px;
        padding-top: 10px;
        &.show {
          //transition: .3s;
          display: block;
          height: auto;
          padding-bottom: 5px;
        }
        & .price-item {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;
          padding-right: 20px;
          padding-bottom: 10px;
          padding-left: 30px;
          height: 60px;
          border-bottom: 1px solid #bababa;
          @media screen and (max-width: 768px) {
            padding: 10px 10px 10px 15px;
            flex-direction: column;
            align-items: flex-start;
            height: 87px;
          }

          &_separate {
            border-bottom: 1px solid $color-grey;
          }

          & .price-title {
            font-size: 16px;
            color: $color-grey;
            font-family: $gothampromedium;
            display: flex;
            flex-direction: column;
            & p{
              margin-bottom: 0;
            }
            @media screen and (max-width: 768px) {
              margin-bottom: 10px;
            }

            & small {
              margin-top: 10px;
              color: #a29f9f;
              font-size: 15px;
              font-family: $gothamproregular;
              @media screen and (max-width: 768px) {
                margin-top: 4px;
              }
            }
          }

          & .price-check {
            width: 290px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media screen and (max-width: 768px){
              width: 100%;
            }
            & .price {
              font-size: 18px;
              font-family: $gothampromedium;
              color: #010101;
            }

            & .check-in {
              display: none;
              color: $color-red;
              font-size: 12px;
              font-family: $gothamproregular;
              @media screen and (max-width: 768px) {
                & span {
                  display: none;
                }
              }

              & svg {
                margin-left: 4px;
                fill: $color-red;
                width: 20px;
                height: 17px;
              }
            }
          }

          &:hover {
            box-shadow: 0 0 4.8px 2.2px rgba(53, 53, 53, 0.13);
            &::before{
              content: "";
              position: absolute;
              left: 0;
              top: -2px;
              height: 3px;
              background-color: #fff;
              width: 100%;
            }
            &::after{
              content: "";
              position: absolute;
              left: 0;
              bottom: -2px;
              height: 3px;
              background-color: #fff;
              width: 100%;
            }
            & .price-check {
              & .check-in {
                display: block;

                &:hover {
                  & span {
                    color: $color-grey;
                  }

                  & svg {
                    fill: $color-grey;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


