.review-item {
  width: 370px;
  background-color: #fff;
  padding-top: 52px;
  padding-left: 28px;
  padding-right: 28px;
  padding-bottom: 20px;

  @media screen and (max-width: 991px) {
    max-width: initial;
    margin: 0 auto;
  }
  @media screen and (max-width: 568px) {
    max-width: 100%;
    width: auto;
    margin: 0 auto;
    padding-left: 13px;
    padding-right: 13px;
  }

  & .review-item-inner {
    & a {
      text-decoration: none;
    }

    & .shadow-box {
      //box-shadow: 0px 7px 20px -5px rgba(0, 0, 0, 0.26);
      box-shadow: -7px 4px 20px -5px rgba(0, 0, 0, .26);
      transition: .1s;
      @media screen and (max-width: 1024px) {
        overflow: hidden;
        margin-bottom: 37px;
      }
    }
  }

  .review-item-heading {
    @media screen and (max-width: 568px) {
      max-width: initial !important;
    }
    &__title {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 11px;
      @media screen and (max-width: 568px) {
        flex-direction: column-reverse;
      }

      & > div {
        display: flex;
        align-items: center;
        @media screen and (max-width: 568px) {
          display: flex;
        }

        & .icon {
          margin-right: 3px;

          & svg {
            fill: #e31e24;
            width: 32px;
            height: 28px;
          }
        }

        & .name {
          font-size: 17px;
          font-family: $gothampromedium;
          color: $color-grey;
        }
      }

      & .rating {
        @media screen and (max-width: 568px) {
          margin-bottom: 20px;
        }

        & svg {
          fill: #9d9d9d;
          height: 13px;
          width: 12px;
          margin-right: 3px;
        }
      }
    }

    &__meta {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;
      padding-right: 28px;
      padding-left: 38px;
      @media screen and (max-width: 568px){
        justify-content: space-between;
        padding-right: 0;
        padding-left: 10px;
      }
      & div {
        &:nth-of-type(1) {
          color: $color-red;
          font-size: 10px;
          font-family: $gothampromedium;
        }

        &:nth-of-type(2) {
          display: flex;
          align-items: center;

          & span {
            margin-right: 10px;

            &:nth-of-type(1) {
              & svg {
                fill: #868686;
                //fill: $color-grey;
                width: 14px;
                height: 14px;
              }
            }

            &:nth-of-type(2) {
              font-size: 10px;
              text-align: left;
              color: #868686;
              font-family: $gothampromedium;
            }
          }
        }
      }
    }
  }


  &__tags {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    & .review-tag {
      padding: 7px 10px;
      opacity: 0.26;
      border-radius: 15px;
      background-color: $color-grey2;
      font-size: 13px;
      border: solid 1px $color-grey2;
      font-family: $gothampromedium;
      color: #fff;
      line-height: 16px;
      text-align: center;
      margin-right: 10px;
      margin-bottom: 10px;
      @media screen and (max-width: 568px) {
        margin-right: 4px;
        padding: 7px 8px;
      }
      &:hover {
        opacity: 0.8;
        border-radius: 15px;
        color: $color-grey2;
        border: solid 1px $color-grey2;
        background-color: #fff;
      }
    }
  }

  &__answer {
    display: none;
    font-family: $gothamproregular;
    font-size: 13px;
    color: $color-grey;
    padding-bottom: 20px;
    margin-top: 15px;
    line-height: 22px;
    & .title{
      font-size: 17px;
      font-family: $gothampromedium;
      color: #555;
    }
  }

  &:hover {
    & .shadow-box {
      box-shadow: -4px -2px 20px -5px rgba(0, 0, 0, .26);
    }
  }

  &__content {
    position: relative;
    font-size: 14px;
    color: $color-grey;
    font-family: $gothamproregular;
    margin-bottom: 20px;
    line-height: 24px;

    & .readmore__hide {
      line-height: 24px;
      margin-bottom: 30px;
      height: 120px;
      overflow: hidden;
      color: $color-grey;

      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 4em;
        background: #fff;
        background: linear-gradient(0deg, rgba(255, 255, 255, .798757) 0, rgba(255, 255, 255, .01) 100%);
      }

      &.active {
        height: auto;

        &::before {
          display: none;
        }
      }
    }

    & .readmore__link {
      float: left;
      font-size: 11px;
      color: $color-grey;
      display: block;
      border-bottom: 1px solid $color-grey;
      padding-bottom: 2px;
      margin-bottom: 35px;
      width: max-content;
      @media screen and (max-width: 568px) {
        clear: both;
        margin-bottom: 10px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    & .readmore-comment {
      float: right;
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        margin-bottom: 40px;

      }
      @media screen and (max-width: 568px){
        position: absolute;
        bottom: -2px;
        right: 0;
      }

      &:hover {
        cursor: pointer;
      }

      & svg {
        fill: $color-grey;
        width: 13px;
        height: 13px;
        transition: .2s;
      }

      & span {
        font-size: 11px;
        display: block;
        color: #555;
        border-bottom: 1px solid #555;
        padding-bottom: 2px;
        margin-left: 5px;
        width: max-content;

        &:nth-of-type(2) {
          display: none;
        }
      }

      &.active {
        & svg {
          transform: rotate(180deg);
        }
      }
    }

    & .add-comment {
      float: left;
      font-size: 11px;
      display: block;
      color: #555;
      border-bottom: 1px solid #555;
      padding-bottom: 2px;
      margin-bottom: 35px;
      width: max-content;
      margin-left: 15px;

      &:hover {
        cursor: pointer;
      }

      @media screen and (max-width: 568px) {
        float: inherit;
        margin-left: 0;
        margin-bottom: 0;
      }
    }
  }
}


.review-item-inner {
  &.active {
    & .review-item__answer {
      display: block;
    }

    & .readmore-comment {
      & span {
        &:nth-of-type(1) {
          display: none;
        }

        &:nth-of-type(2) {
          display: block;
        }
      }

      & svg {
        transform: rotate(180deg);
      }
    }
  }
}

.staff-card-review{
  & .review-item__tags{
    @media screen and (max-width: 568px){
      justify-content: center;
    }
    & .review-tag{
      margin-right: 7px;
      padding: 6px 7px;
    }
  }
}