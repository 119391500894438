.callback-section {
  background-color: #eeeeee;
  margin-bottom: 55px;
  @media screen and (max-width: 768px) {
    margin-bottom: 38px;
  }

  &_ask {
    margin-bottom: 131px;

    & .callback-section-title {
      flex-direction: row !important;
      align-items: center !important;

      & p {
        margin-bottom: 0 !important;
        margin-right: 15px;
        @media screen and (max-width: 768px) {
          margin-right: 0;
        }
      }
    }
  }
}

.callback-section__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 42px;
  padding-bottom: 37px;
  @media screen and (max-width: 1120px) {
    flex-direction: column;
  }

  & .callback-section-title {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column !important;
      line-height: 34px;
      text-align: center !important;
    }

    & p {
      font-size: 24px;
      color: $color-grey;
      font-family: $gothampromedium;
      margin-bottom: 10px;
      @media screen and (max-width: 1120px) {
        font-size: 20px;
        line-height: 21px;
      }
    }

    font-size: 20px;
    color: $color-grey;
    font-family: $gothamproregular;
    @media screen and (max-width: 1120px) {
      font-size: 17px;
      margin-bottom: 25px;
      line-height: 22px;
      text-align: center;
      & p{
        text-align: center;
      }
    }
  }

  & form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    @media screen and (max-width: 1120px) {
      flex-direction: column;
    }

    & .form-group {
      width: 220px;

      & input {
        width: 220px;
        padding-right: 10px;
        padding-left: 0;
        border: none;
        border-bottom: 1px solid $color-grey;
        border-radius: 0;
        background-color: initial;
      }
    }

    & .form-error {
      display: none;
    }
  }
}

.callback-section.call {
  & .callback-section__inner {
    justify-content: space-around;
  }
}


.sm-none .callback-section__inner {
  max-width: 847px;
  @media screen and (max-width: 1024px){
    max-width: initial;
  }
}