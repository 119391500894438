/* Load basic styles/utilities */
@import "lib/normalize/normalize";
//@import "../../node_modules/bulma/bulma";
//@import "base/bulma-vars";
//@import "node_modules/bulma/sass/utilities/functions.sass";
//@import "node_modules/bulma/sass/utilities/derived-variables.sass";
//@import "node_modules/bulma/sass/utilities/animations.sass";
//@import "node_modules/bulma/sass/utilities/mixins.sass";
//@import "node_modules/bulma/sass/utilities/controls.sass";
//@import "node_modules/bulma/sass/base/_all";
//@import "node_modules/bulma/sass/elements/_all";
//@import "node_modules/bulma/sass/components/_all";
//@import "node_modules/bulma/sass/grid/_all";
//@import "node_modules/bulma/sass/layout/_all";
@import "base/vars";
@import "base/fonts";
@import "base/base";
@import "layout/modal/modal.scss";
@import "utilities/u-buttons";

//Bootstrap grip
@import "lib/bootstrap/bootstrap.min.css";
@import "lib/bootstrap/bootstrap-grid.min.css";
@import "lib/slick/slick.css";
@import "lib/slick/slick-theme.scss";
@import "lib/datepicker/datepicker.css";



//@import "node_modules/rfs/scss/rfs";

//@import "base/mixin";
/*@import "lib/grid/container";*/


/*@import "base/icons";*/
//@import "base/setting";
@import "base/form";


//@import "modules/alerts/alerts.scss";
@import "lib/owl-carousel/owl.carousel.min.css";
@import "lib/owl-carousel/owl.theme.default.min.css";
@import "lib/picker/timepicker.scss";
@import "lib/fancybox/jquery.fancybox.css ";



@import "layout/header/header-home.scss";
@import "layout/header/header-second.scss";
@import "layout/header/header-third.scss";
@import "modules/breadcrumbs/breadcrumbs.scss";
@import "layout/content/main-content.scss";
@import "layout/banners/banners.scss";
@import "layout/content/privilege.scss";
@import "modules/contact-map/contact-map.scss";
@import "modules/banner-slider/banner-slider.scss";
@import "modules/our-service/our-service.scss";
@import "modules/our-advantages/our-advantages.scss";

@import "modules/owlc/owlc-main.scss";

@import "modules/select/select-custom.scss";
@import "modules/all-staff/all-staff.scss";
@import "modules/selection/filter-selection.scss";
@import "modules/scroll-custom/scroll-custom.scss";

@import "layout/footer/footer-home.scss";
@import "layout/footer/footer-secondary.scss";

@import "layout/forms/online-registrations.scss";
@import "layout/forms/asking.scss";
@import "layout/forms/leave-review.scss";
@import "layout/forms/leave-question.scss";

@import "modules/intro-about/intro-about.scss";
@import "modules/sidebar/sidebar-links.scss";
@import "modules/sidebar/sidebar-accordion.scss";
@import "modules/sidebar/sidebar-subscribe.scss";
@import "modules/sidebar/sidebar-asking.scss";
@import "modules/fx/pulse.scss";
@import "modules/accordion/price-accordion.scss";
@import "modules/callback/callback-section.scss";
@import "modules/callback/callback-section-ask.scss";
@import "modules/doctor/doctor-main.scss";
@import "modules/service/service-main.scss";
@import "modules/service/service-heading.scss";
@import "modules/service/service-gallery.scss";

@import "modules/review-section/review-section.scss";
@import "modules/voprosi-section/voprosi-section.scss";
@import "modules/pagination/pagination.scss";
@import "modules/rating/rating.scss";
@import "modules/blog/blog.scss";
@import "modules/share/share.scss";
@import "modules/return-to-top/return-to-top.scss";
//@import "layout/header/header.scss";
//@import "layout/header/header-quest.scss";
//@import "layout/account/account.scss";
//@import "layout/search-page/search-page.scss";
//@import "layout/recovery-pass/recovery-pass.scss";
//@import "layout/brand/brand-page.scss";

//@import "modules/home-search/home-search.scss";
//@import "modules/ajax-search/ajax-search.scss";
//@import "modules/breadcrumbs/breadcrumbs.scss";
//@import "modules/quest-promo/quest-promo.scss";
//@import "modules/quest-items/quest-item.scss";
//@import "modules/rating-stars/rating-stars.scss";
//@import "modules/rating-stars/rating-count.scss";
//@import "modules/filter/filter.scss";
//@import "modules/page404/page404.scss";
//@import "modules/navigation-arrows/navigation-arrows.scss";
@import "layout/new-style/center-style.scss";

//@import "layout/quest/quest.scss";
//@import "layout/quest/quest-sertificate.scss";
//@import "layout/quest/quest-genre.scss";
//@import "layout/modal/modal.scss";
//@import "layout/blog/blog.scss";
//@import "modules/pagination/pagination.scss";
//@import "modules/map/map-detail.scss";
//@import "modules/partner/partner.scss";
//@import "modules/lang-tabs/lang-tabs.scss";


//@import "lib/texteditor/trumbowyg.min.css";




//Account cabinet styles
//@import "layout/account/account.scss";



//Load style for other page
//@import "layout/section-faq/section-faq.scss";

//Load dtyles for common modules

//@import "modules/offer-item-carousel/offer-item-carousel.scss";


//@import "layout/l-footer";
//@import "layout/l-main";
//@import "layout/l-secondary";
//@import "layout/l-header-content";
//@import "layout/l-line-devide";
//@import "layout/**/*.scss";

//TODO объеденить папки и сделать везде мейн файл которые уже собирать тут

/* Load utilities */
/*@import "utilities/u-indents";*/

//@import "utilities/u-dropdowns";
/*@import "utilities/u-tooltips";*/
//@import "utilities/u-main";
//@import "utilities/u-text";
/*@import "utilities/u-forms";*/

//libs
//@import "lib/owl.carousel/owl.carousel";
//@import "lib/owl.carousel/owl.theme.default.min";
//@import "lib/magnific-popup/main";

//modules/pages

/*@import "modules!**!*.scss";*/

/* Load states  */
//@import "states/*.scss";



//page list
//#showMenu {
//  position: fixed;
//  z-index: 9999;
//  right: 0px;
//  top: 60px;
//  //display: none;
//}
//
//#showMenu a{
//  padding: 5px 30px;
//}

//#showMenu{
//  &:hover{
//    & .dropdown-menu{
//      display: block;
//      top: 61%;
//      left: -122px;
//    }
//  }
//}

input, button{
  outline: none !important;
}
input, button:hover{
  outline: none !important;
}
input, button:focus{
  outline: none !important;
}
input, button:active{
  outline: none !important;
}

input, button:focus {outline:0;}

button:active {
  //outline: none  !important;
  //border: none  !important;
}

input, button:focus {outline:0 !important;
}

*:focus {
  outline: 0 !important;
}


//.modal-tabs input:-webkit-autofill,
//.modal-tabs input:-webkit-autofill:hover,
//.modal-tabs input:-webkit-autofill:focus,
//.modal-tabs input:-webkit-autofill:active,
//.modal-tabs textarea:-webkit-autofill,
//.modal-tabs textarea:-webkit-autofill:hover
//.modal-tabs textarea:-webkit-autofill:focus,
//.modal-tabs select:-webkit-autofill,
//.modal-tabs select:-webkit-autofill:hover,
//.modal-tabs select:-webkit-autofill:focus {
//  transition: background-color 5000s ease-in-out 0s;
//  color: #b6bbe9 !important;
//  -webkit-text-fill-color: #b6bbe9 !important;
//}



//#search input:-webkit-autofill,
//#search input:-webkit-autofill:hover,
//#search input:-webkit-autofill:focus,
//#search input:-webkit-autofill:active,
//#search textarea:-webkit-autofill,
//#search textarea:-webkit-autofill:hover
//#search textarea:-webkit-autofill:focus,
//#search select:-webkit-autofill,
//#search select:-webkit-autofill:hover,
//#search select:-webkit-autofill:focus {
//   transition: background-color 5000s ease-in-out 0s;
//   color: #fff !important;
//   -webkit-text-fill-color: #fff !important;
// }


//.filter-checker input[type=radio], .filter-checker input[type=checkbox] {
//  position: absolute;
//  top: 0;
//  left: 0;
//  visibility: visible;
//  z-index: -1;
//  width: 2px;
//  height: 1px;
//}

//page list
#showMenu {
  position: fixed;
  z-index: 9999;
  right: 0px;
  top: 60px;
  //display: none;
}
//
#showMenu a{
  padding: 5px 30px;
}

#showMenu{
  &:hover{
    & .dropdown-menu{
      display: block;
      top: 14px;
      left: -166px;
      & .dropdown-content{
        line-height: 20px;
      }
    }
  }
}

.mapinfo{
  font-family: $gothamproregular;
  font-size: 12px;
  line-height: 18px;
  & span{
    font-family: $gothampromedium;
    font-size: 13px;
    margin-bottom: 4px;
  }
}

.p-bt-0{
  padding-bottom: 0;
  margin-bottom: 0;
}