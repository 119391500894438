#return-to-top {
  z-index: 99999;
  position: fixed;
  bottom: 20px;
  right: 20px;
  //background: rgb(0, 0, 0);
  //background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-red;
  width: 40px;
  height: 40px;
  //display: block;
  text-decoration: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  & svg {
    fill: #fff;
    width: 20px;
    height: 20px;
    transform: rotate(180deg);
  }
  @media screen and (max-width: 560px) {
    bottom: 5px;
    right: 5px;
  }
}

#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 16px;
  top: 13px;
  font-size: 19px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top:hover {
  background: $color-grey2;
}

#return-to-top:hover i {
  color: #fff;
  top: 5px;
}