.staff-container{
  padding-top: 155px !important;
  @media screen and (max-width: 991px){
    padding-top: 0 !important;
  }
  & .breadcrumbs{
    padding-top: 0;
  }
}

.all-staff {
  & .row{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    //width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: start;
    align-items: flex-start;
    & .staff-item {
      width: 25%;
      max-width: 262px;
      //margin-bottom: 2rem;
      //padding: 1rem;
      //width: 262px;
      //float: left;
      margin-right: 15px;
      margin-left: 15px;
      margin-bottom: 70px;
      @media screen and (max-width: 1025px) {
        width: 33.3%;
      }
      @media screen and (max-width: 875px){
        width: 50%;
      }
      @media screen and (max-width: 583px){
        width: 100%;
      }
      &__description{
        margin-bottom: 40px;
        @media screen and (max-width: 991px){
          margin-bottom: 0;
        }
      }
    }
  }

}

//.all-staff {
//  & .row {
//    & .staff-item {
//      &:last-child{
//        margin-right: 0;
//      }
//    }
//  }
//}