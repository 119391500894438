.advantages {
  margin-top: 84px;
  background-color: #fff;
  background-image: url(../img/advantages-bg.png);
  padding-bottom: 200px;
  background-position-x: 66%;
  background-position-y: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 1366px){
    background-position-x: 81%;
  }
  @media screen and (max-width: 991px) {
    padding-bottom: 304px!important;
    background-position-y: bottom!important;
    margin-bottom: 40px!important;
    background-position-x: center;
  }
  @media screen and (max-width: 568px) {
    padding-left: 0;
    padding-right: 0;
    height: auto;
    margin-bottom: 0;
    //background-position-x: -92px;
    //background-position-y: bottom;
    //background-size: 500px 500px;
    //padding-bottom: 175px!important;
    background-size: 300px 300px;
    padding-bottom: 111px!important;
    background-position: center;
  }

  //@media screen and (max-width: 320px) {
  //  background-position-x: -176px;
  //}

  & .title {
    font-size: 36px;
    margin-bottom: 108px;
    @media screen and (max-width: 991px) {
      margin-bottom: 41px;
      font-size: 22px;
    }
    @media screen and (max-width: 568px){
      margin-bottom: 30px;
    }
  }

  & .container {
    max-width: 1400px;
  }
}

.advantages-info {
  padding: 60px 46px 60px 42px;
  box-shadow: 0px 5px 6.8px 1.2px rgba(0, 0, 0, 0.11);
  background-color: #fefefe;
  color: $color-grey2;
  font-size: 15px;
  font-family: $gothampromedium;
  line-height: 24px;
  @media screen and (max-width: 991px) {
    margin-bottom: 60px;
    box-shadow: 0px 5px 14.9px 1.1px rgba(53, 53, 53, 0.1);
  }
  @media screen and (max-width: 568px){
    padding: 30px 10px 30px 10px;
    font-family: $gothamproregular;
    font-size: 15px;
  }

  & p {
    margin-bottom: 20px;
  }
}

.advantages-list {
  flex-direction: column;
  justify-content: center;
  height: 100%;

  & ul {
    & li {
      position: relative;
      margin-bottom: 33px;
      padding-left: 50px;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 21px;
      text-align: justify;
      color: $color-grey;
      font-family: $gothampromedium;
      padding-bottom: 4px;
      @media screen and (max-width: 1200px) {
        font-size: 14px;
      }
      @media screen and (max-width: 991px) {
        font-size: 19px;
        text-align: left;
      }
      @media screen and (max-width: 568px) {
        font-size: 13px;
        margin-bottom: 15px;
        text-align: left;
      }

      &::before {
        content: "";
        position: absolute;
        top: 9px;
        left: 0px;
        height: 5px;
        width: 25px;
        background-color: $color-red;
      }
    }
  }
}