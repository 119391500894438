.video-item{
  position: relative;
  & svg{
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    fill: $color-red;
    transition: .2s;
    width: 30px;
    height: 30px;
    z-index: 1;
    top: 45%;
  }
  &:hover{
    & svg{
      fill: #fff;
    }
  }
}


.video-carousel {
  & .owl-nav {
    display: block !important;
  }

  & .owl-nav .owl-prev {
    position: absolute;
    left: -28px;
    top: -61px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 1024px) {
      left: -16px;
    }
    @media screen and (max-width: 991px) {
      left: -15px;
      top: -20px;
    }
    @media screen and (max-width: 768px){
      top: -75px;
    }
    @media screen and (max-width: 568px){
      top: -61px;
    }
    //@media screen and (max-width: 568px){
    //
    //}
  }

  & .owl-nav .owl-next {
    position: absolute;
    right: -21px;
    top: -61px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 1024px) {
      right: -16px;
    }
    @media screen and (max-width: 991px) {
      right: -19px;
      top: -20px;
    }
    @media screen and (max-width: 768px){
      top: -75px;
    }
    @media screen and (max-width: 568px){
      top: -61px;
    }
  }

  & .owl-prev.disabled,
  & .owl-next.disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  & .prev-slide {
    background: url(../img/icons/nav-prev.png) no-repeat scroll 0 0;
    background-size: contain;
    //left: -33px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 768px){
   width: 10px;

    }
  }

  & .next-slide {
    background: url(../img/icons/nav-next.png) no-repeat scroll 0 0;
    background-size: contain;
    //right: -33px;
    height: 30px;
    width: 16px;
    @media screen and (max-width: 768px){
      width: 10px;

    }
  }

  & .prev-slide:hover {
    //background-position: 0px -53px;
  }

  & .next-slide:hover {
    //background-position: -24px -53px;
  }
}