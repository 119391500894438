.review-section {
  background-color: #f4f4f4;
  padding: 37px 70px;
  margin-bottom: 45px;
  @media screen and (max-width: 991px){
    padding: 37px 20px;
  }
  @media screen and (max-width: 568px){
    padding: 37px 0px;
    margin-bottom: 20px;
  }
  & .review-item {
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 64px;
    @media screen and (max-width: 568px){
     max-width: 100%;
    }
    & .review-item-heading {
      max-width: 314px;
    }
    &__tags{
      max-width: 400px;
      margin-bottom: 27px;
    }
    &__answer{
      & .title{
        font-size: 15px;
        text-align: left;
        margin-bottom: 10px;
      }
    }
    .add-comment__block{
      & .form-row{
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}
.review-content{
  padding-top: 30px;
  @media screen and (max-width: 991px){
    padding-top: 0;
  }
}
.review-content .question-item{
  box-shadow: 0 3px 10px -1px rgba(0,0,0,.08);
}

.question-section .question-item__content .readmore-comment{
  @media screen and (max-width: 568px){
    margin-top: 25px;
  }
}