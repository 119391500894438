.slider {
  //height: 100%;
  @media screen and (max-width: 991px) {
    //padding-bottom: 27px;
    padding-bottom: 0;
  }
}

.slider.small .banner-slider {
  position: relative;
  font-family: $gothamproregular;
  font-size: 42px;
  color: $color-grey;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 991px) {
    //display: flex;
    //justify-content: center;
    //flex-direction: column;
    //align-items: center;
    //text-align: center !important;
    //max-width: initial !important;
  }

  & p {
    font-family: $gothampromedium;
    @media screen and (max-width: 991px) {
      font-size: 18px !important;
    }

    &:nth-of-type(2) {
      font-family: $gothamproregular;
      font-size: 36px;
      margin-bottom: 5px;
      @media screen and (max-width: 991px) {
        font-family: $gothamproregular;
        font-size: 16px !important;
        margin-bottom: 5px;
        line-height: 22px;
      }
    }
  }

  &__item {
    height: 616px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      background: rgb(255,255,255);
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 36%, rgba(255,255,255,1) 100%);
    }

    @media screen and (max-width: 991px) {
      height: 365px;
    }
    @media screen and (max-width: 568px) {
      height: 320px;
    }
  }

  &__inner {
    font-size: 28px;
    text-align: left;
    height: 100%;
    //max-width: 585px;
    justify-content: flex-start;
    padding-top: 0;
    @media screen and (max-width: 991px) {
      padding-top: 27px;
    }

    & p {
      font-family: $gothampromedium;

      &:nth-of-type(1) {
        font-size: 42px;
        margin-bottom: 28px;
        max-width: 768px;
        @media screen and (max-width: 568px) {
          margin-bottom: 12px;
        }
      }

      &:nth-of-type(2) {
        font-size: 36px;
        line-height: 46px;
        margin-bottom: 56px;
        max-width: 70%;
        @media screen and (max-width: 991px) {
          margin-bottom: 33px;
          line-height: 22px;
        }
        @media screen and (max-width: 568px) {
          margin-bottom: 14px;
          max-width: 100%;
          line-height: 20px;
        }
      }

      &:nth-of-type(3) {
        text-align: center;
        max-width: 200px;
        @media screen and (max-width: 991px) {
          & .btn {
            &.grey_inherit {
              border: 2px solid $color-grey2;
              background-color: $color-grey2;
              color: #fff;

              &:hover {
                border: 2px solid $color-grey2;
                background-color: inherit;
                color: $color-grey2;
                cursor: pointer;
              }
            }
          }
        }
        @media screen and (max-width: 660px){
          max-width: initial;
        }
      }
    }
  }
}

.owl-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 25px;
  width: 100%;
}

.owl-dots .owl-dot {
  border-radius: 50%;
  border: 2px solid $color-red;
  opacity: 0.21;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  background-color: #5b5b5b !important;
}

.owl-dots .owl-dot.active {
  opacity: 1;
  border: 2px solid $color-red;
  background-color: $color-red !important;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.owl-nav {
  display: none;
}


